import { useTranslation } from "react-i18next";
import PEPage from "app/shared/UI/PEPage";
import studyFilters from "app/study/StudyFilters";
import StudyListingPageContent from "app/study/StudyListingPageContent";

const StudyListingPage = (props) => {
  const { t } = useTranslation();

  return (
    <PEPage
      hasDrawer={true}
      drawerComponent={studyFilters}
      pageTitle={t("pageTitle.studyListing")}
      hasBackButton={false}
      contentComponent={StudyListingPageContent}
      {...props}
    />
  );
};

export default StudyListingPage;
