// NOTE: This file is maintained in the parent project TDS-AS Common.
//       Your application should avoid modifying this file.

import { AlertsProvider, Theme, useAuth, UsersProvider, Page } from "common";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { App } from "app/App";

export const AppRoot = () => {
  const auth = useAuth();

  return (
    <div>
      {auth.isAuthenticated && (
        <MuiThemeProvider theme={Theme}>
          <BrowserRouter>
            <AlertsProvider>
              <UsersProvider>
                <Page>
                  <App />
                </Page>
              </UsersProvider>
            </AlertsProvider>
          </BrowserRouter>
        </MuiThemeProvider>
      )}
    </div>
  );
};
