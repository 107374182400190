// NOTE: This file is maintained in the parent project TDS-AS Common.
//       Your application should avoid modifying this file.

import ReactDOM from "react-dom";
import { AppRoot } from "AppRoot";
import { AuthProvider, setupAxios } from "common";
import reportWebVitals from "reportWebVitals";
import "common/i18n";

setupAxios();

ReactDOM.render(
  // <React.StrictMode>
  <AuthProvider>
    <AppRoot />
  </AuthProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
