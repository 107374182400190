import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  outrachTableOutline: {
    "&& .MuiTableCell-root": {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  outreachTableCellCustomPadding: {
    "&& .MuiTableCell-root": {
      padding: "0px",
    },
    "&& .MuiGrid-item": {
      padding: "8px",
    },
    "&& .MuiInputBase-input": {
      textAlign: "right",
      padding: "0px",
    },
  },
  OutreachDetailsTitle: {
    "&&": {
      borderBottom: `1px solid ${theme.palette.common.black}`,

      "&& h2": {
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",

        svg: {
          marginRight: "6px",
        },
      },
    },
  },
}));
