import { Drawer } from "@material-ui/core";
import { useStyles } from "app/shared/UI/PEDrawerStyles";

const PEDrawer = (props) => {
  const { children, openDrawer } = props;
  const classes = useStyles(props);

  return (
    <Drawer
      classes={{ root: classes.drawer, paper: classes.drawerPaper }}
      variant="persistent"
      anchor="left"
      open={openDrawer}
    >
      {children}
    </Drawer>
  );
};

export default PEDrawer;
