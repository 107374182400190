import { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Grid,
  TableSortLabel,
  TablePagination,
  Link,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getProspectCohortHistory } from "app/services/prospectServices";
import { useAlerts } from "common";
import {
  CohortHistoryDefaults,
  GET_IMPORTS_URL_BASE,
} from "app/shared/constants";
import PEExport from "app/shared/UI/PEExport";

const ProspectDetailsCohortHistory = (props) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const { mrn } = props;
  const [cohortHistory, setCohortHistory] = useState([]);
  const [cohortHistoryFilters, setCohortHistoryFilters] = useState({
    sort: `${CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_ORDERBY},${CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_ORDER}`,
    page: CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_PAGE,
    size: CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_PAGESIZE,
  });
  const [sort, setSort] = useState({
    order: CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_ORDER,
    orderBy: CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_ORDERBY,
  });
  const [page, setPage] = useState(
    cohortHistoryFilters.page ||
      CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_PAGE
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    cohortHistoryFilters.size ||
      CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_PAGESIZE
  );
  const [cohortHistoryTotalCount, setCohortHistoryTotalCount] = useState(0);

  const createSortHandler = (property) => (event) =>
    handleRequestSort(event, property);

  const handleRequestSort = (event, property) => {
    const direction =
      sort.orderBy === property && sort.order === "asc" ? "desc" : "asc";
    setSort({
      orderBy: property,
      order: direction,
    });

    setCohortHistoryFilters({
      ...cohortHistoryFilters,
      sort: `${property},${direction}`,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCohortHistoryFilters({
      ...cohortHistoryFilters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_PAGE);
    setRowsPerPage(parseInt(event.target.value, 10));
    setCohortHistoryFilters({
      ...cohortHistoryFilters,
      page: CohortHistoryDefaults.COHORT_HISTORY_DEFAULT_PAGE,
      size: parseInt(event.target.value, 10),
    });
  };

  useEffect(() => {
    getProspectCohortHistory(
      mrn,
      setCohortHistory,
      cohortHistoryFilters,
      setAlert,
      setCohortHistoryTotalCount
    );
  }, [mrn, setAlert, cohortHistoryFilters]);

  const cohortHistoryHeadCells = [
    { id: "irbNumber", label: "irb", sort: true },
    { id: "fileName", label: "fileName", sort: true },
    { id: "sourceType", label: "cohortSourceType" },
    { id: "importedDate", label: "cohortImportedDate", sort: true },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                {cohortHistoryHeadCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align ? headCell.align : ""}
                  >
                    {headCell.sort ? (
                      <TableSortLabel
                        active={sort.orderBy === headCell.id}
                        direction={
                          sort.orderBy === headCell.id ? sort.order : "asc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {t(`ProspectDetailsCohortHistory.${headCell.label}`)}
                      </TableSortLabel>
                    ) : (
                      t(`ProspectDetailsCohortHistory.${headCell.label}`)
                    )}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {!cohortHistory.length && (
                  <TableRow>
                    <TableCell colSpan="8" align="center">
                      {t("ProspectDetailsCohortHistory.emptyTableRow")}
                    </TableCell>
                  </TableRow>
                )}
                {cohortHistory.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Link
                        href={`${window.location.origin}/outreach/${row.studyId}/conduct`}
                        target="_blank"
                        rel="noopener noreferrer"
                        replace
                      >
                        {row.irbNumber}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <PEExport
                        displayName={row.fileName}
                        // TODO: use wavename_<filename>
                        downloadName={row.fileName}
                        exportURL={`${GET_IMPORTS_URL_BASE}/${row.importId}/uploaded-file`}
                      />
                    </TableCell>
                    <TableCell>{row.sourceType}</TableCell>
                    <TableCell>
                      {new Date(row.importedDate).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cohortHistoryTotalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProspectDetailsCohortHistory;
