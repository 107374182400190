import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useStyles } from "app/shared/UI/PEExportStyles";
import axios from "axios";
import { useAlerts } from "common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "./PEConfirmation";

const PEExport = (props) => {
  const { exportURL, displayName, downloadName, channel } = props;
  const { setAlert } = useAlerts();
  const classes = useStyles();
  const { t } = useTranslation();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const QUALTRICS_FILE = "DE_QUALTRICS_FILE";
  const MARKETO_FILE = "DE_MARKETO_FILE";
  const DIRECT_EMAIL_CHANNEL = "DE";

  const [fileType, setFileType] = useState(QUALTRICS_FILE);

  const handleClickImport = () => {
    setOpenASConfirmation(true);
  };

  const closeCancelASConfirmation = (okOnClose) => {
    setOpenASConfirmation(false);
    setFileType(QUALTRICS_FILE);
    if (okOnClose) {
      handleDownloadFile();
    }
  };

  const handleSelectChange = (e) => {
    setFileType(e.target.value);
  };

  const handleDownloadFile = () => {
    const apiUrl = exportURL + `?directEmailFileType=${fileType}`;
    axios
      .get(apiUrl)
      .then((res) => {
        // save to local file system
        let aTag = document.createElement("a");
        aTag.href = "data:attachment/csv," + encodeURIComponent(res.data);
        aTag.target = "_blank";
        aTag.download = downloadName != null ? downloadName : displayName;
        document.body.appendChild(aTag);
        aTag.click();
        aTag.parentNode.removeChild(aTag);
      })
      .catch((err) => {
        // set error.message in AlertContext
        setAlert("error", err.message);
      });
  };

  const title = t("ExportFile.exportTitle");
  const message = t("ExportFile.exportMessage");
  const okLabel = t("ExportFile.exportOk");
  const cancelLabel = t("ExportFile.exportCancel");

  return (
    <>
      {channel === DIRECT_EMAIL_CHANNEL ? (
        <Dialog open={openASConfirmation}>
          <DialogTitle id="confirmation-dialog-title">
            {title || ""}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("ExportFile.select_label")}
              </InputLabel>
              <Select
                label="Select File Type"
                onChange={handleSelectChange}
                value={fileType}
              >
                <MenuItem value={QUALTRICS_FILE}>
                  {t("ExportFile.qualtrics_file")}
                </MenuItem>
                <MenuItem value={MARKETO_FILE}>
                  {t("ExportFile.marketo_file")}
                </MenuItem>
              </Select>
            </FormControl>
            <div style={{ marginTop: "7px" }}>{message}</div>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => closeCancelASConfirmation(false)}
              color="primary"
            >
              {cancelLabel ? cancelLabel : "Cancel"}
            </Button>
            <Button
              onClick={() => closeCancelASConfirmation(true)}
              color="primary"
            >
              {okLabel ? okLabel : "OK"}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <ConfirmationDialog
          open={openASConfirmation}
          onClose={closeCancelASConfirmation}
          title={t("ExportFile.exportTitle")}
          message={t("ExportFile.exportMessage")}
          okLabel={t("ExportFile.exportOk")}
          cancelLabel={t("ExportFile.exportCancel")}
        />
      )}

      <div className={classes.downloadContainer} onClick={handleClickImport}>
        {displayName}
      </div>
    </>
  );
};

export default PEExport;
