import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: "30px",
    paddingBottom: "24px",
  },
  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  customPadding: {
    paddingTop: "15px",
    "&& .MuiGrid-item": {
      overflowWrap: "break-word",
    },
  },
  customTopPadding: {
    paddingTop: "20px",
  },
  datePicker: {
    "&& .MuiFormControl-root": {
      marginTop: "0px",
    },
  },
}));
