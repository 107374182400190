import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  errorSummaryTitle: {
    "&&": {
      borderBottom: `1px solid ${theme.palette.common.black}`,

      "&& h2": {
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",

        "& svg": {
          marginRight: "6px",
        },
      },
    },
  },
  errorSummaryContent: {
    "&&": {
      padding: "0",
    },
  },
  itemTextBlock: { display: "flex", paddingBottom: "5px" },
  itemTextSpan: { minWidth: "80px", paddingRight: "5px" },
  contentListItem: {
    "&&": {
      backgroundColor: theme.palette.common.white,
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
}));
