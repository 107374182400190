import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  LookupProspectPageCustomPadding: {
    paddingRight: "30px",
    "&& .MuiTableCell-stickyHeader": {
      zIndex: "0",
    },
    "&& .MuiTableSortLabel-icon": {
      alignSelf: "center",
    },
  },
  customTableCell: {
    "&& .MuiTableCell-root": {
      verticalAlign: "text-bottom",
    },
    "&& .MuiList-padding": {
      padding: "0px",
    },
    "&& .MuiListItem-gutters": {
      padding: "0px",
    },
  },
  customNoteTableCell: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100px",
    display: "block",
    overflow: "hidden",
  },
}));
