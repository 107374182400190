import styled from "styled-components/macro";
import { RadioGroup } from "@material-ui/core";

const PERadioGroup = styled(RadioGroup)`
  && {
    display: flex;
    flex-direction: row;
  }
`;

export default PERadioGroup;
