import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableContainer } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { TargetAccrualsRoot } from "./StudyTargetAccrualsStyles";
import { roundTo } from "../shared/utils";
import { useStudies } from "../services/studiesService";

const applyFormat = (val, format) => {
  if (val === undefined || val === null) return "";

  if (typeof val === "object" && "displayName" in val) return val.displayName;

  if (typeof format === "function") return format(val);

  return val.toString();
};

const headCells = [
  {
    id: "group",
    label: ["head_label_group"],
    width: "16em",
    //    tooltip: "head_tooltip_group",
    totalRender: "",
  },
  {
    id: "value",
    label: ["head_label_value"],
    //    tooltip: "head_tooltip_value",
    align: "right",
    totalRender: "TOTAL",
    width: "16em",
  },
  {
    id: "benchmark",
    label: ["head_label_benchmark"],
    format: (x) => (x * 100).toFixed(2) + "%",
    width: "14em",
    align: "right",
    totalRender: (val) => applyFormat(val, (x) => (x * 100).toFixed(2) + "%"),
  },
  {
    id: "target",
    label: ["head_label_target"],
    format: (x) => (x * 100).toFixed(2) + "%",
    width: "14em",
    align: "right",
    totalRender: (val) => applyFormat(val, (x) => (x * 100).toFixed(2)),
  },
  {
    id: "actual",
    label: ["head_label_actual"],
    format: (x) => (!isNaN(x) ? (x * 100).toFixed(2) + "%" : "-"),
    width: "16em",
    align: "right",
    totalRender: (val, targetAccruals) =>
      targetAccruals
        ? applyFormat(val, (x) => (!isNaN(x) ? (x * 100).toFixed(2) + "%" : ""))
        : "",
  },
  {
    id: "delta",
    label: ["head_label_delta"],
    format: (x) => (!isNaN(x) ? (x * 100).toFixed(2) + "%" : "-"),
    width: "10em",
    align: "right",
    totalRender: "",
  },
  {
    id: "ranking",
    label: ["head_label_ranking"],
    width: "2em",
    align: "center",
    totalRender: "",
  },
];

const StudyTargetAccrualsTable = (props) => {
  const { t } = useTranslation();

  const { initData, hookcontrol, isOptimizationEnabled, setBadTargetFields } =
    props;

  const {
    recalcTotalsInTargetAccruals,
    validateValueTarget,
    preValidateValueTarget,
  } = useStudies();
  const targetAccruals = initData.targetAccruals;

  const [rows, setRows] = useState([]);
  const [emptyRows, setEmptyRows] = useState(false);
  const [rendFlag, setRendFlag] = useState(false);

  const [categoriesOut] = useState(
    targetAccruals?.categories ? [...targetAccruals?.categories] : []
  );

  useEffect(() => {
    hookcontrol.register({ name: "categoriesOut", value: [...categoriesOut] });
  }, [categoriesOut, hookcontrol]);

  const headCellLabels = {
    value: [`Target Accrual = ${initData?.protocolTargetAccrual || "0"}`],
  };

  const sanitizeTargetInput = (event, control, fieldName) => {
    const val = event.target.value + "";
    const cleaned = preValidateValueTarget(val);
    if (val !== cleaned) {
      control.setValue(fieldName, cleaned);
      return false;
    } else {
      return true;
    }
  };

  const validateTargetInput = (val, fieldName) => {
    return validateValueTarget(val);
  };

  useEffect(() => {
    setRows(
      targetAccruals &&
        targetAccruals.categories &&
        targetAccruals.categories.length
        ? targetAccruals.categories.map((categorySpec, index) =>
            categoryRows(categorySpec, index, rendFlag, setRendFlag)
          )
        : null
    );
    setEmptyRows(
      !(
        targetAccruals &&
        targetAccruals.categories &&
        targetAccruals.categories.length
      )
    );
    // eslint-disable-next-line
  }, [targetAccruals, rendFlag, setRendFlag, isOptimizationEnabled]);

  useEffect(() => {
    const btFields = {};
    if (
      targetAccruals &&
      targetAccruals.categories &&
      hookcontrol.getValues().categories
    ) {
      for (let index = 0; index < targetAccruals.categories.length; index++) {
        if (targetAccruals.categories.values) {
          for (let vInd = 0; vInd < targetAccruals.categories.values; vInd++) {
            const fieldName = `categories.${index}.values.${vInd}.target`;
            const val =
              hookcontrol.getValues().categories[index].values[vInd].target;
            btFields[fieldName] = !validateTargetInput(val, fieldName);
          }
        }

        if (hookcontrol.getValues().categories[index].totals) {
          const totFieldName = `categories.${index}.totals.target`;
          btFields[totFieldName] =
            +hookcontrol.getValues().categories[index].totals.target !== 100.0;
        }
      }
    }
    setBadTargetFields({ ...btFields });
    // eslint-disable-next-line
  }, [targetAccruals && targetAccruals.categories, rows]);

  const categoryRows = (categorySpec, index, rendFlag, setRendFlag) => {
    const label = categorySpec.label.displayName;
    const labelId = categorySpec.label.id;

    const fieldNames = categorySpec.values.map(
      (v, vInd) => `categories.${index}.values.${vInd}.target`
    );
    categorySpec.values.forEach((v) => {
      v["group"] = "";
    });

    const getProtocolAccrualDependentValues = (value, format) => {
      return initData?.protocolTargetAccrual ? applyFormat(value, format) : "-";
    };

    const valCells = categorySpec.values.map((v, vInd) =>
      headCells.map((hc) => (
        <TargetAccrualsRoot.TableRowCell
          key={hc.id}
          align={hc.align || "inherit"}
          className={hc.id === "delta" && +v["delta"] < 0 ? "negative" : ""}
        >
          {hc.id === "target" ? (
            <TargetAccrualsRoot.TextField
              onChange={(e) => {
                if (
                  !sanitizeTargetInput(e, hookcontrol, fieldNames[vInd]) ||
                  !validateTargetInput(e.target.value, fieldNames[vInd])
                ) {
                  setRendFlag(!rendFlag);
                  return;
                }

                const newValues = hookcontrol
                  .getValues()
                  .categories[index].values.map((val) => {
                    const sum = (roundTo(+val["target"], 2) / 100).toFixed(5);
                    return sum * 1;
                  });
                recalcTotalsInTargetAccruals(
                  targetAccruals,
                  index,
                  newValues,
                  initData?.protocolTargetAccrual
                );
                categoriesOut[index].values.forEach((val, i) => {
                  val["target"] = newValues[i];
                });
                hookcontrol.setValue("categoriesOut", [...categoriesOut]);
                setRendFlag(!rendFlag);
              }}
              error={categorySpec.totals["target"] !== 1}
              required
              defaultValue={(
                targetAccruals.categories[index].values[vInd]["target"] * 100.0
              ).toFixed(2)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              name={fieldNames[vInd]}
              inputRef={hookcontrol.register}
              disabled={
                initData.protocolSummaryAccrual || !isOptimizationEnabled
              }
              variant="outlined"
            />
          ) : (
            <span>
              {["delta", "actual", "ranking"].includes(hc.id)
                ? getProtocolAccrualDependentValues(v[hc.id], hc.format)
                : applyFormat(v[hc.id], hc.format)}
            </span>
          )}
        </TargetAccrualsRoot.TableRowCell>
      ))
    );

    const totCells = headCells.map((hc) => (
      <TargetAccrualsRoot.TableRowCell
        key={hc.id}
        align={hc.align || "inherit"}
      >
        {hc.id === "target" ? (
          <TargetAccrualsRoot.TotalField
            value={
              typeof hc.totalRender === "string"
                ? hc.totalRender
                : hc.totalRender(categorySpec.totals[hc.id])
            }
            error={categorySpec.totals["target"] !== 1}
            helperText={
              categorySpec.totals["target"] !== 1
                ? t(`formValidation.wrongTotalPct`)
                : ""
            }
            name={`categories.${index}.totals.target`}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            inputRef={hookcontrol.register}
            variant="outlined"
            disabled
          />
        ) : typeof hc.totalRender === "string" ? (
          hc.totalRender
        ) : (
          hc.totalRender(
            categorySpec.totals[hc.id],
            initData?.protocolTargetAccrual
          )
        )}
      </TargetAccrualsRoot.TableRowCell>
    ));

    return (
      <>
        {
          <TargetAccrualsRoot.StudyTableRow althighlight={1}>
            <TargetAccrualsRoot.TableRowCell colSpan={headCells.length}>
              {headCells.map((hc, i) => (i === 0 ? label : " "))}
            </TargetAccrualsRoot.TableRowCell>
          </TargetAccrualsRoot.StudyTableRow>
        }
        {valCells.map((vRow, vInd) => (
          <TargetAccrualsRoot.StudyTableRow key={`${labelId}_${vInd}`}>
            {vRow}
          </TargetAccrualsRoot.StudyTableRow>
        ))}
        <TargetAccrualsRoot.StudyTableRow className={"totals-row"}>
          {totCells}
        </TargetAccrualsRoot.StudyTableRow>
      </>
    );
  };

  const displayHeadCell = (headCell, t, headCellLabels) =>
    headCellLabels && headCellLabels[headCell.id] ? (
      headCellLabels[headCell.id].length > 1 ? (
        <TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
          <span>{headCellLabels[headCell.id][0]}</span>
          <span>{headCellLabels[headCell.id][1]}</span>
        </TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
      ) : (
        headCellLabels[headCell.id][0]
      )
    ) : headCell.label.length > 1 ? (
      <TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
        <span>{t("targetAccrualsTable." + headCell.label[0])}</span>
        <span>{t("targetAccrualsTable." + headCell.label[1])}</span>
      </TargetAccrualsRoot.StudyTableHead.TwoRowHeadCell>
    ) : (
      t("targetAccrualsTable." + headCell.label[0])
    );

  const displayHeadCellWithToolTip = (headCell, t, headCellLabels) => (
    <TargetAccrualsRoot.LightTooltip
      title={t("targetAccrualsTable." + headCell.tooltip)}
      placement="top"
    >
      <div>{displayHeadCell(headCell, t, headCellLabels)}</div>
    </TargetAccrualsRoot.LightTooltip>
  );

  const EnhancedTableHead = (props) => {
    return (
      <TargetAccrualsRoot.StudyTableHead className={"gray-med-bg"}>
        <TargetAccrualsRoot.StudyTableRow>
          {headCells.map((headCell) => (
            <TargetAccrualsRoot.StudyTableHead.StudyTableHeadCell
              key={headCell.id}
              headcell={headCell}
              align={headCell.align || "inherit"}
            >
              {headCell.tooltip
                ? displayHeadCellWithToolTip(headCell, t, props.headCellLabels)
                : displayHeadCell(headCell, t, props.headCellLabels)}
            </TargetAccrualsRoot.StudyTableHead.StudyTableHeadCell>
          ))}
        </TargetAccrualsRoot.StudyTableRow>
      </TargetAccrualsRoot.StudyTableHead>
    );
  };

  return (
    <TargetAccrualsRoot>
      <TableContainer>
        <Table>
          <EnhancedTableHead headCellLabels={headCellLabels} />
          <TableBody>
            {rows}
            {emptyRows && (
              <TableRow>
                <TargetAccrualsRoot.EmptyTableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TargetAccrualsRoot>
  );
};

export const StudyTargetAccrualsTab = (props) => {
  return (
    <>
      <StudyTargetAccrualsTable {...props} />
    </>
  );
};

export default StudyTargetAccrualsTab;
