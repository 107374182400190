export const navbar = {
  width: 786,
  items: [
    {
      name: "Studies",
      menuItem: [
        { name: "Study Listing", route: "/" },
        { name: "Edit Study", route: "/studies/edit" },
      ],
    },
    {
      name: "Prospects",
      menuItem: [
        { name: "Import Prospects", route: "/prospects/import" },
        { name: "Lookup Prospect", route: "/prospects/lookup" },
        {
          name: "Import Participants",
          route: "/prospects/import/participants",
        },
      ],
    },
    {
      name: "Outreach",
      menuItem: [
        { name: "Setup Outreach", route: "/outreach/setup" },
        {
          name: "Conduct/View Outreach",
          route: "/outreach/conductview",
        },
      ],
    },
    {
      name: "Reports",
      menuItem: [
        {
          name: "Portfolio Reports",
          route: "/portfolio/reports",
        },
        {
          name: "Study Reports",
          route: "/study/reports",
        },
      ],
    },
    {
      name: "Admin",
      menuItem: [
        { name: "Manage Users", route: "/manage/users" },
        { name: "Manage Tags", route: "/manage/tags" },
      ],
    },
  ],
};
