const PEMail = (props) => {
  const { emailAddress, children } = props;

  return (
    <a target="_top" href={"mailto:" + emailAddress}>
      {children}
    </a>
  );
};

export default PEMail;
