import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContainer: {
    marginBottom: "15px",
  },
  gridSection: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: "0px 12px",
  },
  outreachTable: {
    border: `1px solid ${theme.palette.grey[300]}`,
    marginTop: "15px",

    "& tbody>tr:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  bestHeaderCell: {
    color: theme.palette.success.main,
    "& svg": {
      verticalAlign: "sub",
    },
  },
  worstHeaderCell: {
    color: theme.palette.warning.main,
    "& svg": {
      verticalAlign: "sub",
    },
  },
  formTextField: {
    width: "100%",
    marginBottom: "15px",
  },
  formButton: {
    marginBottom: "15px",
  },
  disablediconstate: {
    color: theme.palette.grey[300],
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiTableSortLabel-icon": {
      alignSelf: "center",
    },
  },
  tableContainerRoot: {
    marginBottom: "20px",
    "& .MuiTableCell-root": {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  overflowTableContainer: {
    overflowX: "auto",
  },
  interestedTableCell: {
    backgroundColor: theme.palette.success.main,
  },
  unsubscribedTableCell: {
    backgroundColor: theme.palette.warning.main,
  },
  cellWithInfoContainer: {
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      paddingLeft: "5px",
    },
  },
  cohortSourceTableCell: {
    overflowWrap: "anywhere",
    minWidth: "20ch",
  },
  timePicker: {
    marginTop: "0px",
  },
  performingCohortTableCell: {
    overflowWrap: "anywhere",
  },
  styledTableRow: {
    "& tr:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[300],
    },
  },

  selectMarginDense: { margin: 0 },
  disabledIconState: {
    color: theme.palette.grey[400],
  },
  tableHeadColor: {
    backgroundColor: theme.palette.primary.light,
  },
  textFieldWidth: { width: "30%" },
  successColor: { color: theme.palette.success.main },
  customMarginBottom: {
    marginBottom: "20px",
  },
  noteTableCell: {
    minWidth: "400px",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  orange: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.dark,
    width: "15px",
    height: "15px",
  },
  blue: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
    width: "15px",
    height: "15px",
  },
  warning: {
    color: theme.palette.warning.main,
  },
  recurringIcon: {
    width: "15px",
    height: "15px",
  },
}));
