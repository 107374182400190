import { useTranslation } from "react-i18next";
import PEPage from "app/shared/UI/PEPage";
import ProspectListingPageContent from "app/prospect/ProspectListingPageContent";
import ProspectFilters from "app/prospect/ProspectFilters";

const ProspectListingPage = (props) => {
  const { t } = useTranslation();

  return (
    <PEPage
      hasDrawer={true}
      drawerComponent={ProspectFilters}
      pageTitle={t("pageTitle.lookupProspect")}
      hasBackButton={false}
      contentComponent={ProspectListingPageContent}
      isDrawerOpen={true}
      {...props}
    />
  );
};

export default ProspectListingPage;
