import { makeStyles } from "@material-ui/core/styles";

const defaultDrawerWidth = 400;

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  pageMain: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },

  pageMainShift: {
    width: `calc(100% - ${defaultDrawerWidth}px)`,
    marginLeft: defaultDrawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  pageContentContainer: {
    width: "100%",
    height: "100%",
    paddingLeft: "30px",
    paddingTop: "24px",
    paddingRight: "30px",
    paddingBottom: "24px",
  },

  PageTitleContainer: {
    width: "100%",
    minHeight: "30px",
    paddingTop: "24px",
    paddingLeft: `${(props) => (props.hasDrawer ? "0px" : "30px")}`,

    "& .MuiIconButton-root": {
      marginRight: "12px",
    },

    "& .MuiTypography-h5": {
      fontWeight: "200",
    },
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
}));
