import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from "@material-ui/core";

// this is confirmation dialog
const ConfirmationDialog = (props) => {
  const { onClose, open, title, message, okLabel, cancelLabel } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="confirmation-dialog-title">{title || ""}</DialogTitle>
      <DialogContent>{message || ""}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {cancelLabel ? cancelLabel : "Cancel"}
        </Button>
        <Button onClick={handleOk} color="primary">
          {okLabel ? okLabel : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
