import axios from "axios";
import { URLs, OutreachStatuses } from "app/shared/constants";

export const getOutreachErrorSummary = async (
  outreach,
  setErrorList,
  setAlert,
  newErrorRecord,
  t
) => {
  try {
    const request = {
      url: `${URLs.GET_OUTREACH_URL_BASE}/${outreach.id}${URLs.GET_STUDY_LISTING_ERROR_SUMMARY}`,
    };

    const response = await axios(request);

    const buildErrorRecords = () => {
      var records = [];
      if (response.data.failureReason) {
        records.push(
          newErrorRecord(
            t(`OutreachFailureSummary.outreach`),
            outreach.waveName,
            response.data.failureReason
          )
        );
      }
      if (
        response.data.report &&
        outreach.status === OutreachStatuses.FINISHED_WITH_ERROR
      ) {
        response.data.report.map((record) => {
          return records.push(
            newErrorRecord(
              t(`OutreachFailureSummary.mrn`),
              record.mrn,
              record.failureReason
            )
          );
        });
      }
      return records;
    };
    if (response) {
      setErrorList(() => buildErrorRecords());
    }
  } catch (error) {
    setAlert("error", error.message);
  }
};
