import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  ListItemText,
  List,
  ListItem,
  Typography,
  Divider,
} from "@material-ui/core";
import { ErrorOutline as ErrorOutlineIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useStyles } from "app/shared/UI/OutreachFailureSummaryStyles";
import {
  getOutreachFailureSummary,
  clearOutreachFailureSummary,
  useStudies,
} from "app/services/studiesService";
import { useAlerts } from "common";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { renderContentBySplitingOnFullStop } from "../utils";

const OutreachFailureSummary = (props) => {
  const {
    onClose,
    open,
    isDisplayConductOutreachButton,
    studyId,
    outreachRefresh,
    setOutreachRefresh,
    history,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [outreachFailureSummaryReport, setOutreachFailureSummaryReport] =
    useState([]);
  const [irbNumber, setIRBNumber] = useState("");

  useEffect(() => {
    getOutreachFailureSummary(
      studyId,
      setIRBNumber,
      setOutreachFailureSummaryReport,
      setAlert
    ); // eslint-disable-next-line
  }, [studyId]);

  const { studies, setStudies } = useStudies();

  const handleClearAlert = () => {
    setIRBNumber("");
    clearOutreachFailureSummary(
      studyId,
      setOutreachFailureSummaryReport,
      setAlert,
      studies,
      setStudies,
      setOutreachRefresh,
      outreachRefresh,
      history
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle id="risk-condition-dialog-title" disableTypography>
        <ErrorOutlineIcon
          color="secondary"
          className={classes.errorOutlineIcon}
        />
        {t(`OutreachFailureSummary.title`)}
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.dialogContent}>
        <List>
          <ListItem alignItems="center" dense={true}>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="subtitle1">
                  {irbNumber && t(`OutreachFailureSummary.irb`)} {irbNumber}
                </Typography>
              }
            />
          </ListItem>
          {outreachFailureSummaryReport &&
          outreachFailureSummaryReport.length ? (
            outreachFailureSummaryReport.map((row, index) => (
              <>
                <ListItem alignItems="center" dense={true}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="subtitle1">
                        {(row.scheduleType === "ONE_TIME_ONLY" &&
                          t(`OutreachFailureSummary.outreach`)) ||
                          t(`OutreachFailureSummary.recurringSchedule`)}{" "}
                        {row.waveName}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" color="textSecondary">
                        {format(new Date(row.issueDate), "yyyy-MM-dd hh:mm a")}{" "}
                        {row.failureReason ? renderContentBySplitingOnFullStop(row.failureReason) : ""}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
              </>
            ))
          ) : (
            <Typography align="center" variant="subtitle1">
              {t(`OutreachFailureSummary.noSummaryAvailable`)}
            </Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions
        className={
          (isDisplayConductOutreachButton && classes.dialogActions) || ""
        }
      >
        <Button
          onClick={() => handleClearAlert()}
          disabled={!outreachFailureSummaryReport.length}
          color="primary"
        >
          {t(`OutreachFailureSummary.clearAlertLabel`)}
        </Button>
        {isDisplayConductOutreachButton && (
          <Link
            to={{
              pathname: `/outreach/${studyId}/conduct`,
            }}
          >
            <Button color="primary">
              {t(`OutreachFailureSummary.goToConductOutreachLabel`)}
            </Button>
          </Link>
        )}
        <Button onClick={onClose} color="primary">
          {t(`Close`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OutreachFailureSummary;
