import { useTranslation } from "react-i18next";
import PEPage from "app/shared/UI/PEPage";
import EditProspectPageContent from "app/prospect/EditProspectPageContent";

const EditProspectPage = (props) => {
  const { t } = useTranslation();

  return (
    <PEPage
      hasDrawer={false}
      pageTitle={t("LookupProspect.prospectDetails")}
      hasBackButton={true}
      backButtonTooltip={t("common.backButtonToolTip")}
      contentComponent={EditProspectPageContent}
      {...props}
    />
  );
};

export default EditProspectPage;
