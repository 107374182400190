import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  Search as MuiSearchIcon,
  ArrowDropDown as MuiArrowDropDownIcon,
} from "@material-ui/icons";
import {
  TextField as MuiTextField,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import { useAlerts } from "common";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useStyles } from "app/study/StudyFiltersStyles";
import * as qs from "qs";

export const AUTOCOMPLETE_DEBOUNCE_DELAY = 500; // Delay after user pauses typing before calling API (in milliseconds)
export const AUTOCOMPLETE_MIN_INPUT = 2; // Minimum number of characters entered by user before calling API

/**
 * `Autocomplete` is a component that renders an MUI autocomplete input for filtering.
 *
 * @component
 * @param {string} label - The label for the autocomplete input.
 * @param {string} value - The value of the autocomplete.
 * @param {Function} onChange - The function to call when the autocomplete input's value changes.
 * @param {boolean} [required=false] - Indicates if the autocomplete input is a required field.
 * @param {boolean} [disabled=false] - Indicates if the autocomplete input is disabled.
 * @param {boolean} [clientSide=false] - Indicates if the autocomplete is a client side.
 * @param {Array} [options=[]] - The initial set of options displayed in the autocomplete dropdown/popup.
 */

let timeoutID;
export const Autocomplete = ({
  label,
  value,
  options = [],
  onChange,
  urlPrefix,
  required = false,
  disabled = false,
  clientSide = false,
  ...otherParams
}) => {
  const classes = useStyles();
  const { setAlert, clearAlert } = useAlerts();
  const { t } = useTranslation();

  const [optionsList, setOptionsList] = useState(options || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (options.length) setOptionsList(options);
  }, [options]);

  const handleSearchStringChange = ({ target }) => {
    const searchQuery = target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(async () => {
      if (searchQuery?.length >= AUTOCOMPLETE_MIN_INPUT) {
        const cancelSource = axios.CancelToken.source();
        try {
          setLoading(true);
          clearAlert();
          const fetchSize = 50;

          const request = {
            url: urlPrefix,
            params: { value: searchQuery, size: fetchSize },
            // paramsSerializer with qs helps us to encode the special characters that are not encoded by the Axios.
            paramsSerializer: {
              serialize: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
              },
            },
            cancelToken: cancelSource?.token,
          };

          const response = await axios(request);
          // TODO REMOVE URL CONDITION
          const options = response?.data?.values || [];
          // pushing helper text as an option when the options are greater than or equal to fetchSize
          if (options.length >= fetchSize) {
            options.push(moreItemsOption(options.length, t));
          }
          setOptionsList(options);
        } catch (error) {
          setAlert("error", error?.message);
        } finally {
          setLoading(false);
        }
      } else {
        setOptionsList([searchTooShortOption(AUTOCOMPLETE_MIN_INPUT, t)]);
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };
  return (
    <MuiAutocomplete
      {...otherParams}
      options={optionsList}
      getOptionDisabled={(option) => option.isDummy}
      defaultValue={null}
      value={value}
      blurOnSelect={true}
      clearOnEscape={true}
      clearOnBlur={true}
      closeIcon={false}
      loading={loading}
      onChange={onChange}
      disabled={disabled}
      onClose={() => !clientSide && setOptionsList([])}
      noOptionsText={"Please type a name to search"}
      popupIcon={clientSide ? <MuiArrowDropDownIcon /> : <MuiSearchIcon />}
      classes={{ root: !clientSide && classes.autocompleteRoot }}
      renderInput={(params) => (
        <RenderInput
          params={params}
          clientSide={clientSide}
          label={label}
          required={required}
          loading={loading}
          handleSearchStringChange={handleSearchStringChange}
        />
      )}
    />
  );
};

const searchTooShortOption = (nMinChars, t) => ({
  value: {},
  displayText: t("Please enter {{nMinChars}} or more characters", {
    nMinChars: nMinChars,
  }),
  isDummy: true,
});

const moreItemsOption = (nResults, t) => ({
  value: {},
  displayText: t("Top {{nResults}} results, enter more characters to refine.", {
    nResults: nResults,
  }),
  isDummy: true,
});

const RenderInput = ({
  params,
  clientSide,
  label,
  required,
  loading,
  handleSearchStringChange,
}) => {
  if (clientSide) {
    return (
      <MuiTextField
        {...params}
        label={label}
        variant="outlined"
        required={required}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? (
                <MuiCircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  }

  return (
    <MuiTextField
      {...params}
      label={label}
      variant="outlined"
      required={required}
      onChange={handleSearchStringChange}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <MuiCircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};
