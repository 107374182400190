import { useTranslation } from "react-i18next";
import PEPage from "app/shared/UI/PEPage";
import ConductOutreachPageContent from "app/outreach/ConductOutreachPageContent";

const ConductOutreachPage = (props) => {
  const { t } = useTranslation();

  return (
    <PEPage
      hasDrawer={false}
      pageTitle={t("pageTitle.conductOutreach")}
      hasBackButton={true}
      backButtonTooltip={t("common.backButtonToolTip")}
      contentComponent={ConductOutreachPageContent}
      {...props}
    />
  );
};

export default ConductOutreachPage;
