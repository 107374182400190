import { useState } from "react";
import { useStyles } from "app/shared/UI/PEPageStyles";
import PEDrawer from "app/shared/UI/PEDrawer";
import PEPageTitle from "app/shared/UI/PEPageTitle";
import PEPageContent from "app/shared/UI/PEPageContent";

const PEPage = (props) => {
  const {
    hasDrawer,
    drawerComponent,
    drawerIconTooltip,
    pageTitle,
    hasBackButton,
    backButtonTooltip,
    backActionCB,
    contentComponent,
    isDrawerOpen,
  } = props;
  const [openDrawer, setOpenDrawer] = useState(isDrawerOpen || false);
  const classes = useStyles(props);

  const handleOpenDrawer = () => {
    setOpenDrawer(() => !openDrawer);
  };

  const DrawerComponent = hasDrawer && drawerComponent ? drawerComponent : null;
  const ContentComponent = contentComponent ? contentComponent : "div";

  return (
    <div className={classes.pageContainer}>
      {hasDrawer && (
        <PEDrawer openDrawer={openDrawer}>
          <DrawerComponent />
        </PEDrawer>
      )}
      <div
        className={`${classes.pageMain} ${
          openDrawer ? classes.pageMainShift : ""
        }`}
      >
        <PEPageTitle
          hasDrawer={hasDrawer}
          openDrawer={openDrawer}
          toggleDrawer={handleOpenDrawer}
          pageTitle={pageTitle}
          hasBackButton={hasBackButton}
          backButtonTooltip={backButtonTooltip}
          backActionCB={backActionCB}
          drawerIconTooltip={drawerIconTooltip}
        />
        <PEPageContent>
          <ContentComponent {...props} />
        </PEPageContent>
      </div>
    </div>
  );
};

export default PEPage;
