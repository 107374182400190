import { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import { useStyles } from "app/prospect/ProspectListingPageStyles";
import { Check as CheckIcon, Edit as EditIcon } from "@material-ui/icons";
import PEMail from "app/shared/UI/PEMail";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getAllLookupProspects } from "app/services/prospectServices";
import { useAlerts } from "common";
import { useProspectContext } from "app/services/prospectContext";
import { maskDOB, maskEmailAddress, maskPhoneNumber } from "app/shared/utils";

const ProspectListingPageContent = (props) => {
  const classes = useStyles();
  const { filters, initialLoad, pagination, setPagination, sort, setSort } =
    useProspectContext();

  const { t } = useTranslation();
  let history = useHistory();
  const [lookupProspectList, setLookupProspectList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { setAlert } = useAlerts();

  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    var pageValue = pagination.page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - pagination.page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      pageValue = 0;
    }
    setPagination({
      page: pageValue,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleEditProspect = (rowData) => {
    if (rowData) {
      history.push({
        pathname: `/prospects/${rowData.mrn}/edit`,
        state: { prospect: rowData },
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      sort.orderBy === property && sort.order === "asc" ? "desc" : "asc";
    setSort({
      orderBy: property,
      order: isAsc,
    });
  };

  const createSortHandler = (property) => (event) =>
    handleRequestSort(event, property);

  useEffect(() => {
    getAllLookupProspects(
      setLookupProspectList,
      setTotalCount,
      setAlert,
      filters,
      pagination,
      sort
    );
    // eslint-disable-next-line
  }, [pagination, sort, filters]);

  const prospectHeadCells = [
    { id: "mrn", label: "tableColumnMRN", sort: true },
    { id: "firstName", label: "tableColumnFirstName", sort: true },
    { id: "lastName", label: "tableColumnLastName", sort: true },
    { id: "email", label: "tableColumnEmail", width: 300 },
    { id: "phone", label: "tableColumnPhone", width: 135 },
    { id: "birthDate", label: "tableColumnDOB", width: 135, sort: true },
    { id: "address", label: "tableColumnAddress" },
    { id: "note", label: "tableColumnNote" },
    { id: "optOut", label: "tableColumnOptOut" },
    { id: "concern", label: "tableColumnConcernOnFile" },
    { id: "action", label: "tableColumnAction" },
  ];

  return (
    <Grid container className={classes.LookupProspectPageCustomPadding}>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {prospectHeadCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  width={headCell.width ? headCell.width : ""}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={sort.orderBy === headCell.id}
                      direction={
                        sort.orderBy === headCell.id ? sort.order : "asc"
                      }
                      onClick={createSortHandler(headCell.id)}
                    >
                      {t(`LookupProspect.${headCell.label}`)}
                    </TableSortLabel>
                  ) : (
                    t(`LookupProspect.${headCell.label}`)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.customTableCell}>
            {lookupProspectList && lookupProspectList.length ? (
              lookupProspectList.map((lookupProspectRow, lookupProspectIdx) => (
                <TableRow key={lookupProspectIdx}>
                  <TableCell>{lookupProspectRow.mrn}</TableCell>
                  <TableCell>{lookupProspectRow.firstName}</TableCell>
                  <TableCell>{lookupProspectRow.lastName}</TableCell>
                  <TableCell>
                    <List>
                      {lookupProspectRow.emails &&
                        lookupProspectRow.emails.map((emailRow, emailIdx) => (
                          <ListItem key={emailIdx}>
                            <PEMail emailAddress={emailRow}>
                              <Tooltip title={emailRow} arrow>
                                <span>{maskEmailAddress(emailRow)}</span>
                              </Tooltip>
                            </PEMail>
                          </ListItem>
                        ))}
                    </List>
                  </TableCell>
                  <TableCell>
                    <List>
                      {lookupProspectRow.phones &&
                        lookupProspectRow.phones.map((phoneRow, phoneIdx) => (
                          <ListItem key={phoneIdx}>
                            <Typography>
                              <Tooltip title={phoneRow} arrow>
                                <span>{maskPhoneNumber(phoneRow)}</span>
                              </Tooltip>
                            </Typography>
                          </ListItem>
                        ))}
                    </List>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={lookupProspectRow.birthDate} arrow>
                      <span>
                        {lookupProspectRow.birthDate &&
                          maskDOB(lookupProspectRow.birthDate)}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <List>
                      {lookupProspectRow.addresses &&
                        lookupProspectRow.addresses.map(
                          (addressRow, addressIdx) => (
                            <ListItem key={addressIdx}>
                              <Tooltip title={addressRow} arrow>
                                <Typography
                                  componnent="span"
                                  variant="body2"
                                  className={classes.customNoteTableCell}
                                >
                                  {addressRow}
                                </Typography>
                              </Tooltip>
                            </ListItem>
                          )
                        )}
                    </List>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={lookupProspectRow.note} arrow>
                      <span className={classes.customNoteTableCell}>
                        {lookupProspectRow.note}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {lookupProspectRow.optOut && <CheckIcon color="primary" />}
                  </TableCell>
                  <TableCell>
                    {lookupProspectRow.concern && <CheckIcon color="primary" />}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={t(`LookupProspect.editProspect`)} arrow>
                      <IconButton
                        size="small"
                        onClick={(e) => handleEditProspect(lookupProspectRow)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : !initialLoad ? (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {t(`LookupProspect.emptyTableRow`)}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {t(`LookupProspect.messageOnTableInitialLoad`)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Grid>
  );
};

export default ProspectListingPageContent;
