import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from "@material-ui/core";
import { Warning as WarningIcon } from "@material-ui/icons";
import { useStyles } from "app/shared/UI/PEWarningDialogStyles";

// this is a warning dialog
const WarningDialog = (props) => {
  const { onClose, open, title, primaryMessage, secondaryMessage, okLabel } =
    props;
  const classes = useStyles();

  const handleOk = () => {
    onClose(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="confirmation-dialog-title">
        <WarningIcon className={classes.warning} />
        {title || ""}
      </DialogTitle>
      <DialogContent>
        <div>{primaryMessage || ""}</div>
        <div>{secondaryMessage || ""}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          {okLabel ? okLabel : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
