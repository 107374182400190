import { useState } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormLabel,
  TextField,
} from "@material-ui/core";
import AntSwitch from "app/shared/UI/AntSwitch";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useTranslation } from "react-i18next";
import { useStyles } from "app/study/CreateEditStudyFormStyles";
import {
  defaultFUDateFrom,
  defaultDateTo,
  RecruitmentStatusOptions,
} from "app/shared/constants";
import { format, isPast, isValid } from "date-fns";
import WarningDialog from "app/shared/UI/PEWarningDialog";

const CreateEditStudyMain = (props) => {
  const {
    initData,
    Controller,
    hookcontrol,
    errors,
    setIsOptimizationEnabled,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const handleFollowUpDateValidation = (date) => {
    // Validation for updating a study
    return format(new Date(date), "MM/dd/yyyy") !==
      format(new Date(), "MM/dd/yyyy") &&
      initData.followUpDate.getTime() !== date.getTime() //this condition is to not trigger validation on initial followup date & today's date.
      ? !isPast(new Date(date))
        ? setOpenASConfirmation(false)
        : setOpenASConfirmation(true)
      : true;
  };
  const closeCancelASConfirmation = () => {
    setOpenASConfirmation(false);
  };

  return (
    <>
      <WarningDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        title={t("formValidation.followUpDateTitle")}
        primaryMessage={t("formValidation.followUpDate")}
        secondaryMessage={t("formValidation.secondaryFollowUpDate")}
      />
      <Grid container justify="space-between" spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="nickName"
            fullWidth
            label={t(`formLabel.studyNickname`)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register}
            size="small"
            defaultValue={initData.nickname}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="title"
            fullWidth
            label={t(`formLabel.studyTitle`)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register({ required: true })}
            size="small"
            error={errors.title ? true : false}
            helperText={errors.title && t("formValidation.requiredField")}
            defaultValue={initData.title}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="oncoreStatus"
            fullWidth
            label={t(`formLabel.oncoreProtocolStatus`)}
            variant="outlined"
            value={initData.protocolStatus || ""}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="oncoreProtocolNumber"
            fullWidth
            label={t(`formLabel.oncoreProtocolNumber`)}
            variant="outlined"
            value={initData.protocolNumber || ""}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            name="riskCount"
            fullWidth
            label={t(`formLabel.riskCount`)}
            variant="outlined"
            value={initData.riskCount || 0}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="recruited"
            fullWidth
            label={t(`formLabel.recruited`)}
            variant="outlined"
            value={
              initData.recruited +
              " (" +
              initData.subjectSummary.enrolled +
              "/" +
              initData.protocolTargetAccrual +
              ")"
            }
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <Typography component="div">
            <FormLabel component="legend" style={{ marginBottom: 5 }}>
              {t("formLabel.suspended")}
            </FormLabel>
            <Grid
              id="suspendedSwitch"
              component="label"
              container
              alignItems="center"
              spacing={1}
            >
              <Grid item>{t("formLabel.switchNo")}</Grid>
              <Grid item>
                <Controller
                  render={({ onChange, value }) => (
                    <AntSwitch
                      checked={value}
                      onChange={(e) => {
                        // onChange's arg will send value into hook form
                        onChange(e.target.checked);
                      }}
                    />
                  )}
                  name="suspended"
                  control={hookcontrol}
                  defaultValue={initData.suspended}
                />
              </Grid>
              <Grid item>{t("formLabel.switchYes")}</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Controller
            as={
              <TextField
                select
                fullWidth
                label={t(`formLabel.recruitmentStatus`)}
                variant="outlined"
                size="small"
                error={errors.recruitmentStatus ? true : false}
                helperText={
                  errors.recruitmentStatus
                    ? t(errors.recruitmentStatus.message)
                    : ""
                }
              >
                <MenuItem value={0}>{t("formLabel.none")}</MenuItem>
                {RecruitmentStatusOptions.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t("recruitmentStatus." + item)}
                  </MenuItem>
                ))}
              </TextField>
            }
            name="recruitmentStatus"
            control={hookcontrol}
            rules={{
              required: true,
              validate: {
                notUninitiated: (value) =>
                  value !== "UNINITIATED" ||
                  t("formValidation.uninitiatedError"),
              },
            }}
            defaultValue={initData.honestBrokerRecruitmentStatus || 0}
          />
        </Grid>

        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                  className={classes.datepicker}
                  format="MM/dd/yyyy"
                  label={t(`formLabel.followUpDate`)}
                  minDate={defaultFUDateFrom}
                  maxDate={defaultDateTo}
                  value={value}
                  onChange={(date) => {
                    if (isValid(new Date(date))) {
                      handleFollowUpDateValidation(date);
                    }
                    onChange(date);
                  }}
                />
              )}
              name="followUpDate"
              control={hookcontrol}
              defaultValue={
                initData.followUpDate
                  ? new Date(initData.followUpDate)
                  : new Date(Date.now() + 12096e5)
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              fullWidth
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              className={classes.datepicker}
              format="MM/dd/yyyy"
              label={t(`formLabel.irbExpirationDate`)}
              value={
                initData.protocolIrbExpirationDate
                  ? initData.protocolIrbExpirationDate
                  : null
              }
              disabled
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} className={classes.customGridMargin}>
          <TextField
            name="studyNote"
            fullWidth
            label={t(`formLabel.note`)}
            variant="outlined"
            inputRef={hookcontrol.register}
            multiline={true}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            defaultValue={initData.note}
          />
        </Grid>

        {initData && !initData.protocolSummaryAccrual ? (
          <Grid item xs={6}>
            <Typography component="div">
              <FormLabel component="legend" style={{ marginBottom: 5 }}>
                {t("targetAccrualsTable.subpopulation_optimization_label")}
              </FormLabel>
              <Grid
                id="optimizationSwitch"
                component="label"
                container
                alignItems="center"
                spacing={1}
              >
                <Grid item>{t("formLabel.switchNo")}</Grid>
                <Grid item>
                  <Controller
                    render={({ onChange, value }) => (
                      <AntSwitch
                        checked={value}
                        onChange={(e) => {
                          // onChange's arg will send value into hook form
                          onChange(e.target.checked);
                          setIsOptimizationEnabled(e.target.checked);
                        }}
                        disabled={initData.protocolSummaryAccrual}
                      />
                    )}
                    name="targetAccruals.isOptimizationEnabled"
                    control={hookcontrol}
                    defaultValue={
                      !initData.protocolSummaryAccrual &&
                      !!initData.targetAccruals?.isOptimizationEnabled
                    }
                  />
                </Grid>
                <Grid item>{t("formLabel.switchYes")}</Grid>
              </Grid>
            </Typography>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default CreateEditStudyMain;
