import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  errorOutlineIcon: {
    verticalAlign: "sub",
    marginRight: "8px",
  },
  divider: { borderBottom: `1px solid ${theme.palette.common.black}` },
  dialogContent: { padding: 0 },
  dialogActions: { justifyContent: "space-between" },
}));
