import { Switch, Route } from "react-router-dom";
import { AccessDenied, useUsers } from "common";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { StudiesProvider } from "app/services/studiesService";
import { FilterProvider } from "app/services/filterService";
import StudyListingPage from "app/study/StudyListingPage";
import { isValidUser } from "app/shared/utils";
import ImportProspectsPage from "app/prospect/ImportProspectsPage";
import ConductOutreachPage from "app/outreach/ConductOutreachPage";
import CreateEditStudyForm from "app/study/CreateEditStudyForm";
import ProspectListingPage from "app/prospect/ProspectListingPage";
import EditProspectPage from "app/prospect/EditProspectPage";
import { ProspectProvider } from "app/services/prospectContext";
import PortfolioReports from "app/reports/PortfolioReports";
import StudyReports from "app/reports/StudyReports";

export const Routes = () => {
  const { currentUser } = useUsers();
  const { t } = useTranslation();

  const getPageByPermission = (page) => {
    // react somehow renders this component twice, once before the
    // user's authentication data is obtained and once after; to
    // optimize for better performance and user experience, we will
    // return the same page during this first rendering and let the
    // page itself handles error alerting when a null user/uid is detected
    if (currentUser == null || currentUser.uid == null) {
      return page;
    }
    if (isValidUser(currentUser)) {
      return page;
    }
    return AccessDenied;
  };

  if (isEmpty(currentUser.permissions) || !isValidUser(currentUser)) {
    return <AccessDenied supportEmail={t("app.supportEmail")} />;
  }

  return (
    <FilterProvider>
      <StudiesProvider>
        <Switch>
          <Route
            exact
            path="/"
            component={getPageByPermission(StudyListingPage)}
          />
          <Route
            exact
            path="/prospects/:studyId/import"
            component={getPageByPermission(ImportProspectsPage)}
          />
          <Route
            exact
            path="/outreach/:studyId/conduct"
            component={getPageByPermission(ConductOutreachPage)}
          />
          <Route
            exact
            path="/studies/new"
            component={getPageByPermission(CreateEditStudyForm)}
          />
          <Route
            exact
            path="/studies/:studyId/edit"
            component={getPageByPermission(CreateEditStudyForm)}
          />
          <ProspectProvider>
            <Route
              exact
              path="/prospects/lookup"
              component={getPageByPermission(ProspectListingPage)}
            />
            <Route
              exact
              path="/prospects/:prospectId/edit"
              component={getPageByPermission(EditProspectPage)}
            />
            <Route
              exact
              path="/portfolio/reports"
              component={getPageByPermission(PortfolioReports)}
            />
            <Route
              exact
              path="/study/reports"
              component={getPageByPermission(StudyReports)}
            />
          </ProspectProvider>
        </Switch>
      </StudiesProvider>
    </FilterProvider>
  );
};
