import { useState } from "react";
import { useTranslation } from "react-i18next";
import PEPage from "app/shared/UI/PEPage";
import ImportProspectsPageContent from "app/prospect/ImportProspectsPageContent";

const ImportProspectsPage = (props) => {
  const { t } = useTranslation();
  const [varInterval, setVarInterval] = useState(0);

  const backBtnCB = () => {
    if (varInterval > 0) {
      clearInterval(varInterval);
    }
  };

  return (
    <PEPage
      hasDrawer={false}
      pageTitle={t("pageTitle.importProspects")}
      hasBackButton={true}
      backActionCB={backBtnCB}
      backButtonTooltip={t("common.backButtonToolTip")}
      contentComponent={ImportProspectsPageContent}
      intervalSetter={setVarInterval}
      {...props}
    />
  );
};

export default ImportProspectsPage;
