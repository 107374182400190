import DateFnsUtils from "@date-io/date-fns";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AccessTime as AccessTimeIcon,
  Autorenew as AutorenewIcon,
  Cancel as CancelIcon,
  ErrorOutline as ErrorOutlineIcon,
  Info as InfoIcon,
  Star as StarIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useStyles } from "app/outreach/ConductOutreachPageStyles";
import OutreachDetails from "app/outreach/OutreachDetails";
import { getOutreachErrorSummary } from "app/services/outreachServices";
import OutreachFailureSummary from "app/shared/UI/OutreachFailureSummary";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import PEErrorDialog from "app/shared/UI/PEErrorDialog";
import PEExport from "app/shared/UI/PEExport";
import {
  DaysOfTheWeek,
  DefaultRecurringParam,
  DemographicOptimization,
  GET_STUDIES_URL_BASE,
  OutreachChannelModes,
  OutreachHistoryDefaults,
  OutreachInitiationModes,
  OutreachScheduleModes,
  OutreachStatuses,
  StudyChannelOptions,
  SummaryAccrual,
  URLs,
} from "app/shared/constants";
import {
  addIndexToElements,
  convertDateStringToLocalDatetime,
  getDisplayPercentage,
  mapOutreachStatsToDisplayed,
  mapStudyToDisplayed,
} from "app/shared/utils";
import axios from "axios";
import { useAlerts } from "common";
import { addMonths, addYears, format } from "date-fns";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const errorSummaryContent = {
  primaryContent: "identifier",
  secondaryContent: "message",
};

const firstOutreachMinDate = new Date(Date.now() + 86400000);

const ConductOutreachPageContent = (props) => {
  const { state, pathname } = props.location;
  const classes = useStyles();
  const [study, setStudy] = useState(state && state.study ? state.study : null);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [refresh, setRefresh] = useState(false);
  const [checkAvailabilityLoader, setCheckAvailabilityLoader] = useState(false);
  const [conductOutreachLoader, setConductOutreachLoader] = useState(false);
  const history = useHistory();

  const [cohortSources, setCohortSources] = useState([]);
  const [prospectCounts, setProspectCounts] = useState([]);
  const [recurringOutreachDate, setRecurringOutreachDate] = useState(
    () => new Date()
  );

  const [outreachHistory, setOutreachHistory] = useState([]);
  const [outreachStatistics, setOutreachStatistics] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOutreach, setSelectedOutreach] = useState(-1);
  const [failureSummaryDialog, setFailureSummaryDialog] = useState(false);
  const [failedOutreach, setFailedOutreach] = useState(-1);
  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [
    openASConfirmationForCancelRecurringWave,
    setOpenASConfirmationForCancelRecurringWave,
  ] = useState(false);
  const [
    openASConfirmationForDisabledChannels,
    setOpenASConfirmationForDisabledChannels,
  ] = useState(false);
  const [isSelectedChannelDisable, setIsSelectedChannelDisable] =
    useState(false);
  const [createOutreachChannelMode, setCreateOutreachChannelMode] = useState(
    OutreachChannelModes.DEFAULT
  );
  const [cancelOutreachRow, setCancelOutreachRow] = useState({});
  const [cancelRecurringOutreachRow, setCancelRecurringOutreachRow] = useState(
    {}
  );

  const [
    overrideExistingRecurringSchedule,
    setOverrideExistingRecurringSchedule,
  ] = useState(false);

  const [
    openASConfirmationForExistingRecurringSchedule,
    setOpenASConfirmationForExistingRecurringSchedule,
  ] = useState(false);

  const [recurringOutreach, setRecurringOutreach] = useState([]);

  const [outreachHistoryFilters, setOutreachHistoryFilters] = useState({
    sort: `${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY},${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDER}`,
    page: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGE,
    size: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGESIZE,
  });
  const [sort, setSort] = useState({
    order: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDER,
    orderBy: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY,
  });
  const [page, setPage] = useState(
    outreachHistoryFilters.page ||
      OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGE
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    outreachHistoryFilters.size ||
      OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGESIZE
  );
  const [outreachHistoryTotalCount, setOutreachHistoryTotalCount] = useState(0);
  const [errorsList, setErrorList] = useState([]);
  const [
    openOutreachFailureSummaryDialog,
    setOpenOutreachFailureSummaryDialog,
  ] = useState(false);

  const newErrorRecord = (type, identifier, message) => {
    return {
      type: type,
      identifier: identifier,
      message: message,
    };
  };

  const handleOutreachErrorContent = (outreach) => {
    getOutreachErrorSummary(
      outreach,
      setErrorList,
      setAlert,
      newErrorRecord,
      t
    );
  };

  const getFirstOutreachDate = (channelName) => {
    if (channelName === StudyChannelOptions.MH) {
      return format(new Date(firstOutreachMinDate), "MM/dd/yyyy");
    }
    return format(new Date(), "MM/dd/yyyy");
  };

  const getOutreachDateAndTime = (channelName, channelEnable) => {
    if (channelName === StudyChannelOptions.MH && channelEnable) {
      return format(
        new Date(firstOutreachMinDate).setHours(0, 0, 0, 0),
        "MM/dd/yyyy hh:mm a"
      );
    }
    return format(new Date(), "MM/dd/yyyy hh:mm a");
  };

  const [formDataResponse] = useState({
    waveName: "",
    source: "",
    channel: "",
    outreachQty: "",
    outreachSchedule: "",
    outreachTime: format(new Date(), "MM/dd/yyyy hh:mm a"),
    firstOutreachDate: format(new Date(), "MM/dd/yyyy"),
    lastOutreachDate: format(addMonths(new Date(), 3), "MM/dd/yyyy"),
    outreachTimeOfDay: format(new Date(), "hh:mm a"),
    waveNote: "",
    scheduleRecurringCheckbox: [],
  });

  const formik = useFormik({
    initialValues: formDataResponse,
    enableReinitialize: true,
    validationSchema: Yup.object({
      waveName: Yup.string().trim().required(),
      source: Yup.string().required(),
      channel: Yup.string().required(),
      outreachQty: Yup.number()
        .integer()
        .min(1)
        .typeError(t("ConductOutreachPage.outreachQuantityErrorMessage"))
        .required(),
      outreachSchedule: Yup.string().required(),
      outreachTime: Yup.mixed().when("outreachSchedule", {
        is: "ONE_TIME_ONLY",
        then: Yup.mixed().required(),
        otherwise: undefined,
      }),
      firstOutreachDate: Yup.mixed().when("outreachSchedule", {
        is: "RECURRING",
        then: Yup.mixed().required(),
        otherwise: undefined,
      }),
      lastOutreachDate: Yup.mixed().when("outreachSchedule", {
        is: "RECURRING",
        then: Yup.mixed().required(),
        otherwise: undefined,
      }),
      outreachTimeOfDay: Yup.mixed().when("outreachSchedule", {
        is: "RECURRING",
        then: Yup.mixed()
          .required()
          .when("channel", {
            is: (channel) => channel !== StudyChannelOptions.MH,
            then: Yup.mixed().required(),
            otherwise: undefined,
          }),
        otherwise: undefined,
      }),
      scheduleRecurringCheckbox: Yup.mixed().when("outreachSchedule", {
        is: "RECURRING",
        then: Yup.array().min(1),
        otherwise: undefined,
      }),
    }),
    onSubmit: ({ setSubmitting }) => {
      handleClickSubmit();
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!state) {
      // in case authorized user refresh the page, thus lose the context that normally
      // will pass from study listing. need to retrieve study by id, strudyId can be gotten
      // from URL, pathname pattern is "/prospects/:studyId/import"
      const index1 = pathname.lastIndexOf("/");
      const studyId = pathname.substring(10, index1);

      // fetch study by id
      axios
        .get(`${GET_STUDIES_URL_BASE}/${studyId}`)
        .then((res) => {
          setStudy(mapStudyToDisplayed(res.data)); // save this for comparison at edit save
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, setAlert, refresh]);

  useEffect(() => {
    if (study) {
      // fetch outreachable imports by irbNumber and source
      axios
        .get(`${URLs.GET_OUTREACHABLE_URL_BASE}?irbNumber=${study.irbNumber}`)
        .then((res) => {
          setCohortSources(res.data); // for cohort source dropdown
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });

      // creating new filters based on single sort and multi-sort
      let filters = {};
      if (Array.isArray(outreachHistoryFilters.sort)) {
        // used URLSearchParams to serialize params and send same param key twice with different values
        var params = new URLSearchParams();
        params.append("sort", outreachHistoryFilters.sort[0]);
        params.append("sort", outreachHistoryFilters.sort[1]);
        params.append("page", outreachHistoryFilters.page);
        params.append("size", outreachHistoryFilters.size);

        filters = params;
      } else {
        filters = outreachHistoryFilters;
      }
      axios
        .get(`${URLs.GET_OUTREACH_HISTORY_URL_BASE}/${study.id}`, {
          params: filters,
        })
        .then((res) => {
          setOutreachHistory(res.data.values); // for cohort source dropdown
          setOutreachHistoryTotalCount(res.data.totalCount);
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });

      axios
        .get(
          `${URLs.GET_OUTREACH_HISTORY_URL_BASE}/${study.id}${URLs.GET_OUTREACH_RECURRING_SCHEDULE_URL_BASE}`,
          {
            params: DefaultRecurringParam,
          }
        )
        .then((res) => {
          setRecurringOutreach(res.data); // for cohort source dropdown
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });
    }
  }, [study, setAlert, outreachHistoryFilters]);

  useEffect(() => {
    // We want to refresh outreach statistics when outreach history changes
    // and we only care about refreshing the stats if there's some outreach
    // history already
    if (study && outreachHistory && outreachHistory.length > 0) {
      axios
        .get(
          `${URLs.GET_OUTREACH_HISTORY_URL_BASE}/${study.id}${URLs.GET_OUTREACH_STATISTICS_URL_BASE}`
        )
        .then((res) => {
          setOutreachStatistics(mapOutreachStatsToDisplayed(res.data)); // for Outreach Statistics
          // tally the total count instead of refreshing the study object from backend
          // this is to elimiate the infinite loop of rendering from study->outreachHistory->study
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });
    }
  }, [study, outreachHistory, setAlert]);

  const handleClickCheckAvailability = () => {
    setCheckAvailabilityLoader(true);
    // check if a cohort source is selected
    if (!formik.values.source) {
      // no cohort source selected
      setCheckAvailabilityLoader(false);
      setAlert("error", t(`ConductOutreachPage.checkAvailabilityAlert`));
    } else {
      // calling BE API /prospects/available-counts?importId=X
      // note: depends on the size of the cohort, this call may take longer
      // than the default 5 seconds so specifying an extended timeout here
      axios
        .get(
          `${URLs.GET_OUTREACH_PROSPECT_COUNTS_URL_BASE}?importId=${formik.values.source}`,
          {
            timeout: process.env.REACT_APP_OUTREACH_REQUEST_TIMEOUT,
          }
        )
        .then((res) => {
          setProspectCounts(res.data); // prospect counts for all channels

          // This is to set outreach quantity when channel is already selected before checking availabity
          formik.setFieldValue(
            "outreachQty",
            getProspectCount(res.data, formik.values.channel, "presetQuantity")
          );
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        })
        .finally(() => {
          setCheckAvailabilityLoader(false);
        });
    }
  };

  const handleChangeChannel = (channelName) => {
    formik.setFieldValue("channel", channelName);
    formik.setFieldValue(
      "outreachQty",
      getProspectCount(prospectCounts, channelName, "presetQuantity")
    );
    setCreateOutreachChannelMode(OutreachChannelModes.DEFAULT);
    setIsSelectedChannelDisable(false);
    formik.setFieldValue("outreachSchedule", "");
    axios
      .get(`${URLs.GET_OUTREACH_CHANNEL_URL_BASE}/${channelName}`)
      .then((res) => {
        if (res.data.status === "DISABLED") {
          setOpenASConfirmationForDisabledChannels(true);
          setIsSelectedChannelDisable(true);
          formik.setFieldValue(
            "outreachTime",
            getOutreachDateAndTime(channelName, false)
          );
        }
      })
      .catch((err) => {
        // set error.message in AlertContext
        setAlert("error", err.message);
      });
  };

  const handleClickSubmit = () => {
    setConductOutreachLoader(true);
    // first check if outreach qty is <= available count
    if (
      formik.values.outreachQty >
      getProspectCount(prospectCounts, formik.values.channel, "available")
    ) {
      // alert message and return
      setAlert(
        "error",
        t(`ConductOutreachPage.validationErrorOutreachQtyGreaterThanAvailable`)
      );
      formik.setSubmitting(false);
      setConductOutreachLoader(false);
      return;
    }
    handleCreateOutreach(overrideExistingRecurringSchedule);
  };

  const handleCreateOutreach = (existingRecurringSchedule) => {
    let scheduleSummary = {};
    if (formik.values.outreachSchedule === "RECURRING") {
      scheduleSummary = {
        endDate: format(new Date(formik.values.lastOutreachDate), "yyyy-MM-dd"),
        overrideExistingSchedule: existingRecurringSchedule,
        recurringDays: formik.values.scheduleRecurringCheckbox,
        recurringTime:
          formik.values.channel !== StudyChannelOptions.MH
            ? format(new Date(recurringOutreachDate), "HH:mm:ss")
            : format(new Date().setHours(0, 0, 0, 0), "HH:mm:ss"),
        startDate: format(
          new Date(formik.values.firstOutreachDate),
          "yyyy-MM-dd"
        ),
        status: "ACTIVE",
      };
    }

    let newData = {
      waveName: formik.values.waveName,
      importRequest: { id: formik.values.source },
      channel: formik.values.channel,
      quantity: formik.values.outreachQty,
      conductedDate:
        (formik.values.outreachSchedule === "ONE_TIME_ONLY" &&
          new Date(formik.values.outreachTime).toISOString()) ||
        null,
      note: formik.values.waveNote,
      scheduleType: formik.values.outreachSchedule,
      scheduleSummary: scheduleSummary,
    };

    createOutreach(newData);
  };

  const createOutreach = (newData) => {
    // note: depends on the size of the cohort, this call may take longer
    // than the default 5 seconds so specifying an extended timeout here
    axios
      .post(
        `${URLs.GET_OUTREACH_URL_BASE}?mode=${createOutreachChannelMode}`,
        newData,
        {
          timeout: process.env.REACT_APP_OUTREACH_REQUEST_TIMEOUT,
        }
      )
      .then((res) => {
        const location = {
          state: null,
        };
        history.replace(location);
        setRefresh((refresh) => !refresh);
        // reset the outreach fields
        handleClickReset();
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          setAlert("error", t("ConductOutreachPage.timeOutErrorMessage"));
        } else if (err.response && err.response.data) {
          if (
            err.response.data.status === 400 &&
            err.response.data.code === "INVALID_ACTION" &&
            err.response.data.detail.properties[0].name === "Conduct Outreach"
          ) {
            // set error.message in AlertContext
            setAlert(
              "error",
              t("ConductOutreachPage.errorMessageForDisabledChannels", {
                channelName: `"${t(
                  "recruitmentPreference." + formik.values.channel
                )}"`,
              })
            );
          } else if (
            err.response.data.status === 400 &&
            err.response.data.code === "INVALID_CHANNEL"
          ) {
            // set error.message in AlertContext
            setAlert(
              "error",
              t("ConductOutreachPage.errorMessageForInvalidChannels", {
                channelName: `"${t(
                  "recruitmentPreference." + formik.values.channel
                )}"`,
              })
            );
          } else if (
            err.response.data.status === 400 &&
            err.response.data.code === "INVALID_ACTION" &&
            err.response.data.detail.properties[0].name === "Outreach Schedule"
          ) {
            // set error.message in AlertContext
            setOpenASConfirmationForExistingRecurringSchedule(true);
          } else if (
            err.response.data.status === 400 &&
            err.response.data.code === "INVALID_VALUE" &&
            err.response.data.detail.properties[0].name === "waveName"
          ) {
            // set error.message in AlertContext
            setAlert(
              "error",
              t("ConductOutreachPage.errorMessageForUniqueWaveName")
            );
          } else {
            setAlert("error", err.message);
          }
        } else {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
        formik.setSubmitting(false);
      })
      .finally(() => {
        setConductOutreachLoader(false);
      });
  };

  const handleClickReset = () => {
    formik.resetForm({ values: formDataResponse });
    setProspectCounts([]);
    setOverrideExistingRecurringSchedule(false);
    setIsSelectedChannelDisable(false);
  };

  const getRecruited = (data) => {
    if (data.protocolTargetAccrual > 0 && data.subjectSummary) {
      const cnt = getDisplayPercentage(
        (data.subjectSummary.enrolled * 100) / data.protocolTargetAccrual
      );
      return (
        cnt +
        "% (" +
        data.prospectOnStudy +
        "/" +
        data.protocolTargetAccrual +
        ")"
      );
    } else {
      return "";
    }
  };

  const getStatsDisplay = (data) => {
    if (data == null || data.value == null || data.percentage == null)
      return t(`NotApplicable`);

    return data.value + " (" + data.percentage + "%)";
  };

  const getCohortStatsDisplay = (data) => {
    if (data == null || data.value == null || data.percentage == null)
      return t(`NotApplicable`);

    return (
      getCohortSourceFileName(parseInt(data.value)) +
      " (" +
      data.percentage +
      "%)"
    );
  };

  const getCohortSourceFileName = (id) => {
    if (cohortSources.length === 0) return "";

    const found = cohortSources.find((item) => item.id === id);
    if (found) return found.filename;

    return "";
  };

  const getProspectCount = (count, channelName, countType) => {
    if (count.length === 0) return "";

    const found = count.find((item) => item.type === channelName);
    if (found) return found[countType];

    return "NA";
  };

  const displayOutreachQuantityDialog = (index) => {
    setOpenDialog(true);
    setSelectedOutreach(index);
  };

  const closeOutreachQuantityDialog = () => {
    setOpenDialog(false);
    setSelectedOutreach(-1);
  };

  const displayFailureSummaryDialog = (index) => {
    setFailureSummaryDialog(true);
    setFailedOutreach(index);
  };

  const closeFailureSummaryDialog = () => {
    setFailureSummaryDialog(false);
    setFailedOutreach(-1);
  };

  const handleClickCancel = () => {
    setOpenASConfirmation(true);
  };
  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    // API call to cancel scheduled outreach will go here.
    if (cancelChoice) {
      axios({
        url: `${URLs.GET_OUTREACH_URL_BASE}/${cancelOutreachRow.id}${URLs.GET_OUTREACH_CANCEL_URL_BASE}`,
        method: "PUT",
      })
        .then((res) => {
          setCancelOutreachRow({});
          const location = {
            state: null,
          };
          history.replace(location);
          setRefresh((refresh) => !refresh);
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });
    }
  };

  const handleClickCancelRecurring = () => {
    setOpenASConfirmationForCancelRecurringWave(true);
  };
  const closeCancelASConfirmationForCancelRecurringWave = (cancelChoice) => {
    setOpenASConfirmationForCancelRecurringWave(false);
    // API call to cancel scheduled outreach will go here.
    if (cancelChoice) {
      axios({
        url: `${URLs.GET_OUTREACH_URL_BASE}${URLs.GET_OUTREACH_RECURRING_SCHEDULE_URL_BASE}/${cancelRecurringOutreachRow.id}${URLs.GET_OUTREACH_CANCEL_URL_BASE}`,
        method: "PUT",
      })
        .then((res) => {
          setCancelRecurringOutreachRow({});
          const location = {
            state: null,
          };
          history.replace(location);
          setRefresh((refresh) => !refresh);
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });
    }
  };

  const closeCancelASConfirmationForDisabledChannels = (cancelChoice) => {
    setOpenASConfirmationForDisabledChannels(false);
    if (cancelChoice) {
      setCreateOutreachChannelMode(OutreachChannelModes.STANDARD);
    } else {
      setCreateOutreachChannelMode(OutreachChannelModes.AUTOMATED);
    }
  };

  const closeCancelASConfirmationForExistingRecurring = (proceedChoice) => {
    setOpenASConfirmationForExistingRecurringSchedule(false);
    if (proceedChoice) {
      setConductOutreachLoader(true);
      setOverrideExistingRecurringSchedule(true);
      handleCreateOutreach(true);
    } else {
      setOverrideExistingRecurringSchedule(false);
      handleClickReset();
    }
  };

  const timeConvert = (time) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };

  const getDaysOfWeekString = (arraySet) =>
    arraySet.map((days, idx) => (idx ? ", " : "") + days.substring(0, 3));

  const sortedArrayOfDaysOfWeek = (data) => {
    let sortedData = [];
    sortedData = data.sort((a, b) => {
      return DaysOfTheWeek.indexOf(a) - DaysOfTheWeek.indexOf(b);
    });

    return getDaysOfWeekString(sortedData);
  };

  const outreachHistoryHeadCells = [
    { id: "waveNumber", label: "tableColumnWaveNumber", align: "center" },
    { id: "outreachMode", label: "tableColumnMode", sort: true },
    { id: "initiationMode", label: "tableColumnRecurringSchedule", sort: true },
    { id: "waveName", label: "tableColumnWaveName", sort: true },
    { id: "status", label: "tableColumnWaveStatus", sort: true },
    { id: "channel", label: "tableColumnChannel", sort: true },
    { id: "cohortSource", label: "tableColumnCohortSource", sort: true },
    { id: "conductedDate", label: "tableColumnTime", sort: true },
    { id: "conductedDay", label: "tableColumnDay", sort: true },
    {
      id: "adjustedOutreachQuantity",
      label: "tableColumnCount",
      sort: true,
      align: "right",
    },
    {
      id: "responded",
      label: "tableColumnResponded",
      sort: true,
      align: "right",
    },
    {
      id: "interested",
      label: "tableColumnInterested",
      sort: true,
      align: "right",
    },
    {
      id: "notInterested",
      label: "tableColumnNotInterested",
      sort: true,
      align: "right",
    },
    {
      id: "unsubscribed",
      label: "tableColumnUnsubscribed",
      sort: true,
      align: "right",
    },
    {
      id: "pepOnStudy",
      label: "tableColumnPepOnStudy",
      sort: true,
      align: "right",
    },
    { id: "note", label: "tableColumnNote", sort: true },
    { id: "action", label: "tableColumnActions" },
  ];

  const createSortHandler = (property) => (event) =>
    handleRequestSort(event, property);

  const handleRequestSort = (event, property) => {
    const isAsc =
      sort.orderBy === property && sort.order === "asc" ? "desc" : "asc";
    setSort({
      orderBy: property,
      order: isAsc,
    });

    //removing sort values from filters before re-assigning to avoid data type conflicts
    let filters = {};
    filters = Object.keys(outreachHistoryFilters)
      .filter((key) => key !== "sort")
      .reduce((obj, key) => {
        obj[key] = outreachHistoryFilters[key];
        return obj;
      }, {});

    //check whether to do single sort or multi-sort
    if (property === OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY) {
      setOutreachHistoryFilters({
        ...filters,
        sort: `${property},${isAsc}`,
      });
    } else {
      setOutreachHistoryFilters({
        ...filters,
        sort: [
          `${property},${isAsc}`,
          `${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY},${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDER}`,
        ],
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOutreachHistoryFilters({
      ...outreachHistoryFilters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setOutreachHistoryFilters({
      ...outreachHistoryFilters,
      page: 0,
      size: parseInt(event.target.value, 10),
    });
  };

  const closeOutreachFailureSummary = () => {
    setOpenOutreachFailureSummaryDialog(false);
  };

  return study || (state && state.study) ? (
    <div className={classes.root}>
      {openOutreachFailureSummaryDialog && (
        <OutreachFailureSummary
          onClose={closeOutreachFailureSummary}
          open={openOutreachFailureSummaryDialog}
          isDisplayConductOutreachButton={false}
          studyId={study.id}
          outreachRefresh={refresh}
          setOutreachRefresh={setRefresh}
          history={history}
        />
      )}
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        message={
          cancelOutreachRow.initiationMode === "USER_INITIATED"
            ? t("ConductOutreachPage.confirmationMessageCancel", {
                waveName: cancelOutreachRow && cancelOutreachRow.waveName,
              })
            : t("ConductOutreachPage.confirmationMessageCancelRecurring", {
                waveName: cancelOutreachRow && cancelOutreachRow.waveName,
              })
        }
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <ConfirmationDialog
        open={openASConfirmationForDisabledChannels}
        onClose={closeCancelASConfirmationForDisabledChannels}
        message={t(
          "ConductOutreachPage.confirmationMessageForDisabledChannel",
          {
            channelName: `"${t(
              "recruitmentPreference." + formik.values.channel
            )}"`,
          }
        )}
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <ConfirmationDialog
        open={openASConfirmationForExistingRecurringSchedule}
        onClose={closeCancelASConfirmationForExistingRecurring}
        message={t("ConductOutreachPage.errorMessageForExistingRecurring")}
        okLabel={t("ConductOutreachPage.proceedLabel")}
        cancelLabel={t("ConductOutreachPage.cancelLabel")}
      />
      <ConfirmationDialog
        open={openASConfirmationForCancelRecurringWave}
        onClose={closeCancelASConfirmationForCancelRecurringWave}
        message={t(
          "ConductOutreachPage.confirmationMessageCancelRecurringSchedule",
          {
            waveName:
              cancelRecurringOutreachRow && cancelRecurringOutreachRow.waveName,
          }
        )}
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={1}>
          <Typography>
            {t(`formLabel.irb`)}{" "}
            <Typography variant="subtitle1" display="inline">
              {study ? study.irbNumber : state.study.irbNumber}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            {`${t("formLabel.summaryAccrual")}:`}{" "}
            <Typography variant="subtitle1" display="inline">
              {study && study.protocolSummaryAccrual
                ? SummaryAccrual.SummaryAccrualTrue
                : SummaryAccrual.SummaryAccrualFalse}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            {`${t("formLabel.demographicOptimization")}:`}{" "}
            <Typography variant="subtitle1" display="block">
              {
                DemographicOptimization[
                  study
                    ? study.demographicOptimizationStatus
                    : state.study.demographicOptimizationStatus
                ]
              }
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            {`${t("formLabel.studyNickname")}: `}{" "}
            <Typography variant="subtitle1" display="inline">
              {study ? study.nickname : state.study.nickname}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          className={classes.gridContainer}
          justify="space-between"
        >
          <Grid item xs={5} className={classes.gridSection}>
            <Typography className={classes.customMarginBottom}>
              {t(`ConductOutreachPage.recommendationLabel`)}
            </Typography>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Typography>
                  {`${t("studyTable.head_label_recruited")}: `}{" "}
                  <Typography variant="subtitle1" display="inline">
                    {study
                      ? getRecruited(study)
                      : `${state.study.recruited} (${state.study.prospectOnStudy}/${state.study.protocolTargetAccrual})`}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {`${t("studyTable.head_label_time_elapsed")}: `}{" "}
                  <Typography variant="subtitle1" display="inline">
                    {study
                      ? study.timeElapsed
                      : `${state.study.timeElapsed} (${state.study.recruitmentDays}/${state.study.recruitmentTotalDays})`}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Typography>
                  {`${t("formLabel.riskCount")}: `}{" "}
                  <Typography variant="subtitle1" display="inline">
                    {study ? study.riskCount : state.study.riskCount}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {`${t("studyTable.head_label_study_end_date")}: `}{" "}
                  <Typography variant="subtitle1" display="inline">
                    {study
                      ? new Date(study.recruitmentEndDate).toLocaleDateString()
                      : new Date(
                          state.study.recruitmentEndDate
                        ).toLocaleDateString()}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Table className={classes.outreachTable} size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <div className={classes.bestHeaderCell}>
                      <StarIcon fontSize="small" />
                      <span>
                        {t(`ConductOutreachPage.recommendationBestLabel`)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.worstHeaderCell}>
                      <WarningIcon fontSize="small" />
                      <span>
                        {t(`ConductOutreachPage.recommendationWorstLabel`)}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t(`ConductOutreachPage.recommendationPerformingDay`)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.dayOfWeekBest)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.dayOfWeekWorst)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(`ConductOutreachPage.recommendationPerformingHour`)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.hourBest)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.hourWorst)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(`ConductOutreachPage.recommendationPerformingChannel`)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.channelBest)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.channelWorst)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(`ConductOutreachPage.recommendationPerformingLotSize`)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.quantityBest)}
                  </TableCell>
                  <TableCell>
                    {getStatsDisplay(outreachStatistics.quantityWorst)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(`ConductOutreachPage.recommendationPerformingCohort`)}
                  </TableCell>
                  <TableCell className={classes.performingCohortTableCell}>
                    {getCohortStatsDisplay(
                      outreachStatistics.importRequestBest
                    )}
                  </TableCell>
                  <TableCell className={classes.performingCohortTableCell}>
                    {getCohortStatsDisplay(
                      outreachStatistics.importRequestWorst
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6} className={classes.gridSection}>
            <Typography className={classes.customMarginBottom}>
              {t(`ConductOutreachPage.outreachSettingsLabel`)}
            </Typography>
            <Grid item xs={12}>
              <TextField
                name="waveName"
                className={classes.formTextField}
                label={t(`ConductOutreachPage.waveNameLabel`)}
                variant="outlined"
                value={formik.values.waveName}
                size="small"
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              container
              justify="space-between"
              className={classes.gridContainer}
            >
              <Grid item xs={8}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  className={classes.selectMarginDense}
                >
                  <InputLabel id="sourceLabel" shrink>
                    {t(`ConductOutreachPage.cohortSourceLabel`)}
                  </InputLabel>
                  <Select
                    label={t(`ConductOutreachPage.cohortSourceLabel`)}
                    labelId="sourceLabel"
                    value={formik.values.source}
                    onChange={(evt) => {
                      setProspectCounts([]);
                      formik.setFieldValue("source", evt.target.value);
                    }}
                    name="source"
                    displayEmpty
                    notched
                  >
                    <MenuItem value={""}>{t("formLabel.none")}</MenuItem>
                    {cohortSources &&
                      cohortSources.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.filename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container justify="flex-end" item xs={4}>
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={checkAvailabilityLoader}
                    onClick={handleClickCheckAvailability}
                  >
                    {t(`ConductOutreachPage.availabilityButtonLabel`)}
                  </Button>
                  {checkAvailabilityLoader && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <TableContainer className={classes.tableContainerRoot}>
              <Table size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell />
                    <TableCell align="right">
                      {t(`ConductOutreachPage.emailLabel`)}
                    </TableCell>
                    <TableCell align="right">
                      {t(`ConductOutreachPage.mychartLabel`)}
                    </TableCell>
                    <TableCell align="right">
                      {t(`ConductOutreachPage.myhealthLabel`)}
                    </TableCell>
                    <TableCell align="right">
                      {t(`ConductOutreachPage.manualLabel`)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      {t(`ConductOutreachPage.availableLabel`)}
                    </TableCell>
                    {StudyChannelOptions &&
                      Object.keys(StudyChannelOptions).map(
                        (channelValue, idx) => (
                          <TableCell key={idx} align="right">
                            <Typography variant="subtitle1">
                              {getProspectCount(
                                prospectCounts,
                                channelValue,
                                "available"
                              )}
                            </Typography>
                          </TableCell>
                        )
                      )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      {t(`ConductOutreachPage.recommendedLabel`)}
                    </TableCell>
                    {StudyChannelOptions &&
                      Object.keys(StudyChannelOptions).map(
                        (channelValue, idx) => (
                          <TableCell key={idx} align="right">
                            <Typography variant="subtitle1">
                              {getProspectCount(
                                prospectCounts,
                                channelValue,
                                "recommended"
                              )}
                            </Typography>
                          </TableCell>
                        )
                      )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              container
              className={classes.customMarginBottom}
              justify={"space-between"}
              xs={12}
            >
              <Grid item xs={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  className={classes.selectMarginDense}
                >
                  <InputLabel id="channelLabel" shrink>
                    {t(`ConductOutreachPage.channelLabel`)}
                  </InputLabel>
                  <Select
                    label={t(`ConductOutreachPage.channelLabel`)}
                    labelId="channelLabel"
                    value={formik.values.channel}
                    onChange={(evt) => {
                      handleChangeChannel(evt.target.value);
                      formik.setFieldValue(
                        "firstOutreachDate",
                        getFirstOutreachDate(evt.target.value)
                      );
                      formik.setFieldValue(
                        "outreachTime",
                        getOutreachDateAndTime(
                          evt.target.value,
                          !isSelectedChannelDisable
                        )
                      );
                    }}
                    name="channel"
                    displayEmpty
                    notched
                  >
                    <MenuItem value={""}>{t("formLabel.none")}</MenuItem>
                    {StudyChannelOptions &&
                      Object.keys(StudyChannelOptions).map((item) => (
                        <MenuItem key={item} value={item}>
                          {t("recruitmentPreference." + item)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs className={classes.textFieldWidth}>
                <TextField
                  name="outreachQty"
                  type="number"
                  label={t(`ConductOutreachPage.outreachQtyLabel`)}
                  variant="outlined"
                  value={formik.values.outreachQty}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  className={classes.selectMarginDense}
                  size="medium"
                  error={
                    formik.touched.outreachQty &&
                    formik.errors.outreachQty &&
                    Boolean(formik.errors.outreachQty)
                  }
                  helperText={
                    formik.touched.outreachQty &&
                    formik.errors.outreachQty &&
                    t("ConductOutreachPage.outreachQuantityErrorMessage")
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  className={classes.selectMarginDense}
                >
                  <InputLabel
                    variant="outlined"
                    required={true}
                    id="outreachScheduleLabel"
                    shrink
                  >
                    {t(`ConductOutreachPage.outreachScheduleLabel`)}
                  </InputLabel>
                  <Select
                    label={t(`ConductOutreachPage.outreachScheduleLabel`)}
                    labelId="outreachScheduleLabel"
                    value={formik.values.outreachSchedule}
                    onChange={formik.handleChange}
                    name="outreachSchedule"
                    displayEmpty
                    notched
                  >
                    <MenuItem value={""}>{t("formLabel.none")}</MenuItem>
                    {Object.values(OutreachScheduleModes).map(function (
                      key,
                      index
                    ) {
                      return (
                        <MenuItem
                          key={index}
                          disabled={
                            (isSelectedChannelDisable ||
                              (formik.values.channel !==
                                StudyChannelOptions.MC &&
                                formik.values.channel !==
                                  StudyChannelOptions.MH)) &&
                            key === "ConductOutreachPage.scheduleRecurring"
                          }
                          value={Object.keys(OutreachScheduleModes).find(
                            (i) => OutreachScheduleModes[i] === key
                          )}
                        >
                          {t(key)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <TextField
              className={classes.formTextField}
              name="waveNote"
              label={t(`formLabel.note`)}
              variant="outlined"
              multiline={true}
              size="small"
              value={formik.values.waveNote}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
            />
            {formik.values.outreachSchedule === "ONE_TIME_ONLY" && (
              <Grid
                container
                className={classes.customMarginBottom}
                justify={"space-between"}
                xs={12}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {formik.values.channel === StudyChannelOptions.MH &&
                  !isSelectedChannelDisable ? (
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      label={t("ConductOutreachPage.outreachDateLabelForMH")}
                      format="MM/dd/yyyy"
                      margin="dense"
                      disablePast
                      value={formik.values.outreachTime}
                      onChange={(date, value) => {
                        formik.setFieldValue("outreachTime", value);
                      }}
                      minDate={firstOutreachMinDate}
                      id="outreachTime"
                      name="outreachTime"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                      minDateMessage={t(
                        "ConductOutreachPage.firstOutreachDateMinDateMessage"
                      )}
                    />
                  ) : (
                    <KeyboardDateTimePicker
                      className={classes.timePicker}
                      hideTabs
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label={t("ConductOutreachPage.outreachDateLabel")}
                      format="MM/dd/yyyy hh:mm a"
                      margin="dense"
                      disablePast
                      value={formik.values.outreachTime}
                      onChange={(date, value) => {
                        formik.setFieldValue("outreachTime", value);
                      }}
                      id="outreachTime"
                      name="outreachTime"
                      required={true}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </Grid>
            )}
            {formik.values.outreachSchedule === "RECURRING" && (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    className={classes.customMarginBottom}
                    container
                    justify={"space-between"}
                  >
                    <Grid item xs={4}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        required={true}
                        label={t("ConductOutreachPage.firstOutreachDateLabel")}
                        format="MM/dd/yyyy"
                        margin="dense"
                        disablePast
                        value={formik.values.firstOutreachDate}
                        minDate={
                          formik.values.channel === StudyChannelOptions.MH
                            ? firstOutreachMinDate
                            : new Date()
                        }
                        onChange={(date, value) => {
                          formik.setFieldValue("firstOutreachDate", value);
                        }}
                        id="firstOutreachDate"
                        name="firstOutreachDate"
                        minDateMessage={t(
                          "ConductOutreachPage.firstOutreachDateMinDateMessage"
                        )}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    {formik.values.channel !== StudyChannelOptions.MH && (
                      <Grid item xs>
                        <KeyboardTimePicker
                          margin={"dense"}
                          variant="inline"
                          inputVariant="outlined"
                          required={true}
                          mask="__:__ _M"
                          label={t("ConductOutreachPage.outreachTimeOfDay")}
                          inputValue={formik.values.outreachTimeOfDay}
                          value={formik.values.outreachTimeOfDay}
                          onChange={(date, value) => {
                            setRecurringOutreachDate(date);
                            formik.setFieldValue("outreachTimeOfDay", value);
                          }}
                          id="outreachTimeOfDay"
                          name="outreachTimeOfDay"
                          fullWidth
                          keyboardIcon={<AccessTimeIcon />}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ autoComplete: "off" }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        required={true}
                        label={t("ConductOutreachPage.lastOutreachDateLabel")}
                        format="MM/dd/yyyy"
                        margin="dense"
                        disablePast
                        value={formik.values.lastOutreachDate}
                        onChange={(date, value) => {
                          formik.setFieldValue("lastOutreachDate", value);
                        }}
                        id="lastOutreachDate"
                        name="lastOutreachDate"
                        maxDate={addYears(
                          new Date(formik.values.firstOutreachDate),
                          1
                        )}
                        minDate={new Date(formik.values.firstOutreachDate)}
                        minDateMessage={t(
                          "ConductOutreachPage.lastOutreachDateMinDateMessage"
                        )}
                        maxDateMessage={t(
                          "ConductOutreachPage.lastOutreachDateMaxDateMessage"
                        )}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
                <FormControl
                  className={classes.customMarginBottom}
                  component="fieldset"
                >
                  <Typography color="textSecondary">
                    {t("ConductOutreachPage.scheduleRecurringLabel")}
                  </Typography>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"scheduleRecurringCheckbox"}
                          color="primary"
                          value={DaysOfTheWeek[0]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("ConductOutreachPage.sundayLabel")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"scheduleRecurringCheckbox"}
                          color="primary"
                          value={DaysOfTheWeek[1]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("ConductOutreachPage.mondayLabel")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"scheduleRecurringCheckbox"}
                          color="primary"
                          value={DaysOfTheWeek[2]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("ConductOutreachPage.tuesdayLabel")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"scheduleRecurringCheckbox"}
                          color="primary"
                          value={DaysOfTheWeek[3]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("ConductOutreachPage.wednesdayLabel")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"scheduleRecurringCheckbox"}
                          color="primary"
                          value={DaysOfTheWeek[4]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("ConductOutreachPage.thursdayLabel")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"scheduleRecurringCheckbox"}
                          color="primary"
                          value={DaysOfTheWeek[5]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("ConductOutreachPage.fridayLabel")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"scheduleRecurringCheckbox"}
                          color="primary"
                          value={DaysOfTheWeek[6]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("ConductOutreachPage.saturdayLabel")}
                    />
                  </FormGroup>
                </FormControl>
              </>
            )}
            <Grid
              container
              item
              className={classes.formButton}
              justify="flex-end"
            >
              <Grid item xs={2}>
                <Button color="primary" onClick={handleClickReset}>
                  {t(`formLabel.buttonReset`)}
                </Button>
              </Grid>
              <Grid container justify="flex-end" item xs={2}>
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      !formik.isValid ||
                      !formik.dirty ||
                      formik.isSubmitting ||
                      prospectCounts.length === 0 ||
                      conductOutreachLoader
                    }
                  >
                    {t(`ConductOutreachPage.submitButtonLabel`)}
                  </Button>
                  {conductOutreachLoader && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid item xs={12} className={classes.gridContainer}>
        <Typography variant="h6">
          {t(`ConductOutreachPage.recurringOutreachLabel`)}
        </Typography>
      </Grid>
      <TableContainer className={classes.customMarginBottom} component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {t(`ConductOutreachPage.recurringTableColumnStartDate`)}
              </TableCell>
              <TableCell>
                {t(`ConductOutreachPage.recurringTableColumnStatus`)}
              </TableCell>
              <TableCell>
                {t(`ConductOutreachPage.recurringTableColumnWaveName`)}
              </TableCell>
              <TableCell>
                {t(`ConductOutreachPage.tableColumnCohortSource`)}
              </TableCell>
              <TableCell>
                {t(`ConductOutreachPage.recurringTableColumnCohortChannel`)}
              </TableCell>
              <TableCell align="right">
                {t(`ConductOutreachPage.recurringTableColumnQuantity`)}
              </TableCell>
              <TableCell>
                {t(`ConductOutreachPage.recurringTableColumnDays`)}
              </TableCell>
              <TableCell>
                {t(`ConductOutreachPage.recurringTableColumnTime`)}
              </TableCell>
              <TableCell>
                {t(`ConductOutreachPage.recurringTableColumnEndDate`)}
              </TableCell>
              <TableCell>{t(`ConductOutreachPage.tableColumnNote`)}</TableCell>
              <TableCell>
                {t(`ConductOutreachPage.tableColumnActions`)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recurringOutreach && recurringOutreach.length ? (
              recurringOutreach.map((recurringRow) => (
                <TableRow>
                  <TableCell>
                    {convertDateStringToLocalDatetime(
                      recurringRow.scheduleSummary.startDate
                    )
                      ? convertDateStringToLocalDatetime(
                          recurringRow.scheduleSummary.startDate
                        ).toLocaleDateString()
                      : null}
                  </TableCell>
                  <TableCell>{recurringRow.scheduleSummary.status}</TableCell>
                  <TableCell width="170">{recurringRow.waveName}</TableCell>
                  <TableCell width="270">
                    {recurringRow.importRequest.filename}
                  </TableCell>
                  <TableCell>{recurringRow.channel}</TableCell>
                  <TableCell align="right">{recurringRow.quantity}</TableCell>
                  <TableCell width="270">
                    {sortedArrayOfDaysOfWeek(
                      recurringRow.scheduleSummary.recurringDays
                    )}
                  </TableCell>
                  <TableCell>
                    {timeConvert(recurringRow.scheduleSummary.recurringTime)}
                  </TableCell>
                  <TableCell>
                    {convertDateStringToLocalDatetime(
                      recurringRow.scheduleSummary.endDate
                    )
                      ? convertDateStringToLocalDatetime(
                          recurringRow.scheduleSummary.endDate
                        ).toLocaleDateString()
                      : null}
                  </TableCell>
                  <TableCell
                    className={recurringRow.note ? classes.noteTableCell : ""}
                  >
                    {recurringRow.note}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={t(
                        "ConductOutreachPage.rowTooltipRecurringCancelIcon"
                      )}
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleClickCancelRecurring();
                          setCancelRecurringOutreachRow(recurringRow);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  {t(`LookupProspect.emptyTableRow`)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container item xs={12} className={classes.customMarginBottom}>
        <Typography variant="h6">
          {t(`ConductOutreachPage.historyLabel`)}
        </Typography>
        {study.isHavingErrorSummary && (
          <Grid container justify="flex-end" item xs>
            <Button
              variant="outlined"
              onClick={() => setOpenOutreachFailureSummaryDialog(true)}
            >
              <ErrorOutlineIcon fontSize="small" color="error" />
              &nbsp;
              {t(`ConductOutreachPage.outreachAutomatedErrorLabel`)}
            </Button>
          </Grid>
        )}
      </Grid>
      <Paper className={classes.customMarginBottom}>
        <TableContainer
          className={classes.overflowTableContainer}
          component={Paper}
        >
          <Table stickyHeader size="small">
            <TableHead className={classes.tableHead}>
              {outreachHistoryHeadCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.align ? headCell.align : ""}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={sort.orderBy === headCell.id}
                      direction={
                        sort.orderBy === headCell.id ? sort.order : "asc"
                      }
                      onClick={createSortHandler(headCell.id)}
                    >
                      {t(`ConductOutreachPage.${headCell.label}`)}
                    </TableSortLabel>
                  ) : (
                    t(`ConductOutreachPage.${headCell.label}`)
                  )}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              <TableRow key={-1}>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  {t(`ConductOutreachPage.tableTotalRowTotals`)}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  <div className={classes.cellWithInfoContainer}>
                    {study && study.outreachSummary.adjustedOutreachQuantity}
                    <InfoIcon
                      color="primary"
                      onClick={() => displayOutreachQuantityDialog()}
                    />
                    {openDialog && (
                      <OutreachDetails
                        onClose={closeOutreachQuantityDialog}
                        open={openDialog}
                        outreach={{
                          id: study.id,
                          quantity:
                            study.outreachSummary.adjustedOutreachQuantity,
                          requestedQuantity: study.outreachSummary.total,
                          outreachSummary: study.outreachSummary,
                          responseSummary: study.responseSummary,
                          subjectSummary: study.subjectSummary,
                        }}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        totalInfo={true}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">
                  {study && study.responseSummary.responded}
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.interestedTableCell}
                >
                  {study && study.responseSummary.interested}
                </TableCell>
                <TableCell align="right">
                  {study && study.responseSummary.notInterested}
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.unsubscribedTableCell}
                >
                  {study && study.responseSummary.unsubscribed}
                </TableCell>
                <TableCell align="right">
                  {study && study.subjectSummary.pepEnrolled}
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>

              {outreachHistory &&
                addIndexToElements(outreachHistory, rowsPerPage, page).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="right">{row.index}</TableCell>
                    <TableCell>
                      {row.outreachMode === OutreachChannelModes.STANDARD ? (
                        <Tooltip
                          title={t("ConductOutreachPage.tootltipStandardMode")}
                          arrow
                        >
                          <Avatar className={classes.orange}>
                            <Typography variant="caption">
                              {row.outreachMode.charAt(0)}
                            </Typography>
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t("ConductOutreachPage.tootltipAutomatedMode")}
                          arrow
                        >
                          <Avatar className={classes.blue}>
                            <Typography variant="caption">
                              {row.outreachMode.charAt(0)}
                            </Typography>
                          </Avatar>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      <Grid container item>
                        {row.initiationMode ===
                        OutreachInitiationModes.SYSTEM ? (
                          <Tooltip
                            title={t(
                              "ConductOutreachPage.tootltipRecurringSchedule"
                            )}
                            arrow
                          >
                            <AutorenewIcon
                              className={classes.recurringIcon}
                              color="primary"
                            />
                          </Tooltip>
                        ) : null}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {row.status &&
                      row.status !== "FAILED" &&
                      row.status !== "SCHEDULED" &&
                      row.status !== "CANCELED" ? (
                        <PEExport
                          displayName={row.waveName}
                          // TODO: use wavename_<filename>
                          downloadName={row.waveName + ".csv"}
                          channel={row.channel}
                          exportURL={`${URLs.GET_OUTREACH_URL_BASE}/${row.id}/file`}
                        />
                      ) : (
                        row.waveName
                      )}
                    </TableCell>
                    <TableCell>
                      {row.status &&
                      (row.status === OutreachStatuses.FAILED ||
                        row.status === OutreachStatuses.FINISHED_WITH_ERROR ||
                        row.status ===
                          OutreachStatuses.GENERATED_WITH_ERROR) ? (
                        <div className={classes.cellWithInfoContainer}>
                          {row.status}
                          <WarningIcon
                            className={classes.warning}
                            onClick={() => {
                              displayFailureSummaryDialog(row.index);
                              handleOutreachErrorContent(row);
                            }}
                          />
                          {failureSummaryDialog &&
                            row.index === failedOutreach && (
                              <PEErrorDialog
                                onClose={closeFailureSummaryDialog}
                                open={failureSummaryDialog}
                                errorsList={errorsList}
                                title={t(`OutreachFailureSummary.title`)}
                                primaryContent={
                                  errorSummaryContent.primaryContent
                                }
                                secondaryContent={
                                  errorSummaryContent.secondaryContent
                                }
                                displayDynamicPrimarySubHeading={true}
                              />
                            )}
                        </div>
                      ) : (
                        <div className={classes.cellWithInfoContainer}>
                          {row.status || t(`ConductOutreachPage.tableColumnNA`)}
                        </div>
                      )}
                    </TableCell>

                    <TableCell>{row.channel}</TableCell>
                    <TableCell className={classes.cohortSourceTableCell}>
                      {row.importRequest.filename}
                    </TableCell>
                    <TableCell>
                      {row.conductedDate
                        ? new Date(row.conductedDate).toLocaleString()
                        : null}
                    </TableCell>
                    <TableCell>{row.conductedDay}</TableCell>
                    <TableCell align="right">
                      <div className={classes.cellWithInfoContainer}>
                        {row.quantity}

                        <InfoIcon
                          color="primary"
                          onClick={() =>
                            displayOutreachQuantityDialog(row.index)
                          }
                        />
                        {openDialog && row.index === selectedOutreach && (
                          <OutreachDetails
                            onClose={closeOutreachQuantityDialog}
                            open={openDialog}
                            outreach={{
                              id: row.id,
                              status: row.status,
                              quantity: row.quantity,
                              requestedQuantity: row.requestedQuantity,
                              outreachSummary: row.outreachSummary,
                              responseSummary: row.responseSummary,
                              subjectSummary: row.subjectSummary,
                              channel: row.channel,
                            }}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            summaryAccrual={study.protocolSummaryAccrual}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {row.responseSummary.responded}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.interestedTableCell}
                    >
                      {row.responseSummary.interested}
                    </TableCell>
                    <TableCell align="right">
                      {row.responseSummary.notInterested}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.unsubscribedTableCell}
                    >
                      {row.channel !== StudyChannelOptions.MC
                        ? row.responseSummary.unsubscribed
                        : "N/A"}
                    </TableCell>
                    {/* TODO: update with correct count */}
                    <TableCell align="right">
                      {row.subjectSummary.pepEnrolled}
                    </TableCell>
                    <TableCell className={classes.noteTableCell}>
                      {row.note}
                    </TableCell>
                    <TableCell>
                      {row.status === OutreachStatuses.SCHEDULED ? (
                        <Tooltip
                          title={t("ConductOutreachPage.rowTooltipCancelIcon")}
                          arrow
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleClickCancel();
                              setCancelOutreachRow(row);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton size="small">
                          <CancelIcon className={classes.disabledIconState} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={outreachHistoryTotalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  ) : null;
};

export default ConductOutreachPageContent;
