import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Button,
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { useStyles } from "app/prospect/EditProspectPageStyles";
import PEMail from "app/shared/UI/PEMail";
import AntSwitch from "app/shared/UI/AntSwitch";
import { useTranslation } from "react-i18next";
import { OptOutReason } from "app/shared/constants";
import {
  getProspectByMrn,
  editProspectById,
} from "app/services/prospectServices";
import { useAlerts } from "common";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { differenceObject } from "app/shared/utils";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import ProspectDetailsOutreachHistory from "app/prospect/ProspectDetailsOutreachHistory";
import ProspectDetailsCohortHistory from "app/prospect/ProspectDetailsCohortHistory";

const EditProspectPageContent = (props) => {
  const { state = { prospect: { emails: [], phones: [], addresses: [] } } } =
    props.location;
  const { prospectId } = props.match.params;
  const { setAlert } = useAlerts();
  const classes = useStyles();
  const { t } = useTranslation();
  const { prospect } = state;
  const history = useHistory();

  const [prospectData, setProspectData] = useState(prospect);
  const { emails = [], phones = [], addresses = [] } = prospectData;

  const [optedStatus, setOptedStatus] = useState(
    prospectData.optOut ? true : false
  );

  useEffect(() => {
    getProspectByMrn(prospectId, setProspectData, setAlert, setOptedStatus);
  }, [prospectId, setAlert]);

  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const formik = useFormik({
    initialValues: prospectData,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      optOut: Yup.object()
        .shape({
          optOutReason: Yup.string()
            .required(
              `Opt-out Reason is required if the prospect has Opted Out`
            )
            .nullable(),
          optOutDate: Yup.date()
            .typeError("Invalid Date Format")
            .required(`Opt-out Date is required if the prospect has Opted Out`)
            .nullable(),
          optOutComment: Yup.string().required(
            `Opt-out Comment is required if the prospect has Opted Out`
          ),
        })
        .nullable()
        .default(undefined),
      concern: Yup.object()
        .shape({
          concernDate: Yup.date()
            .typeError("Invalid Date Format")
            .required(`Concern Date is required if Concern is present`)
            .nullable(),
          concernDescription: Yup.string()
            .required(`Concern is required if Concern Date is present`)
            .nullable(),
        })
        .nullable()
        .default(undefined),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      editProspectById(prospectId, diff1, setAlert, setProspectData, history);
    },
  });
  const diff1 = differenceObject(formik.values, prospectData);

  const handleClickCancel = () => {
    if (formik.dirty) {
      setOpenASConfirmation(true);
    } else {
      history.push("/prospects/lookup");
    }
  };
  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    if (cancelChoice) {
      history.push("/prospects/lookup");
    }
  };

  const getValueSeparator = (value, type) => {
    if (type === "email") {
      return value.map((row, idx) => (
        <Typography key={idx} variant="subtitle1">
          <PEMail emailAddress={row}>{(idx ? ", " : "") + row}</PEMail>
        </Typography>
      ));
    }
    return <Typography variant="subtitle1">{value.join(", ")}</Typography>;
  };

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        message={t(`OutreachDetails.confirmationMessageCancel`)}
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography>{t("LookupProspect.prospectDetails")}</Typography>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnMRN")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">{prospectData.mrn}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnFirstName")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">
                  {prospectData.firstName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnLastName")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">
                  {prospectData.lastName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnEmail")}:
              </Grid>
              <Grid container item xs={9}>
                {getValueSeparator(emails, "email")}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnPhone")}:
              </Grid>
              <Grid container item xs={9}>
                {getValueSeparator(phones)}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnDOB")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">{prospectData.dob}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnAddress")}:
              </Grid>
              <Grid item xs={9}>
                {addresses.map((row, idx) => (
                  <Typography key={idx} variant="subtitle1">
                    {row}
                    <Divider orientation="horizontal" />
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={formik.handleSubmit}>
            <Paper className={classes.paper}>
              <Typography>{t("LookupProspect.editProspect")}</Typography>

              <Grid container item xs={12} className={classes.customPadding}>
                <Grid item xs={3}>
                  {t("LookupProspect.optedStatus")}
                </Grid>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle1">In</Typography>
                    </Grid>
                    <Grid item>
                      <AntSwitch
                        name="optedStatus"
                        checked={optedStatus}
                        onChange={(evt) => {
                          setOptedStatus(evt.target.checked);
                          if (evt.target.checked) {
                            formik.setValues((prevValues) => ({
                              ...prevValues,
                              optOut: {
                                optOutDate: new Date().toISOString(),
                              },
                            }));
                          } else {
                            formik.setValues((prevValues) => ({
                              ...prevValues,
                              optOut: null,
                            }));
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">Out</Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.customTopPadding}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  error={
                    formik.errors.optOut &&
                    formik.touched.optOutReason &&
                    Boolean(formik.errors.optOut.optOutReason)
                  }
                  className={classes.selectMarginDense}
                >
                  <InputLabel
                    variant="outlined"
                    required={optedStatus}
                    disabled={!optedStatus}
                    id="optOutReason"
                    shrink
                  >
                    {t("LookupProspect.optOutReason")}
                  </InputLabel>
                  <Select
                    label={t("LookupProspect.optOutReason")}
                    labelId="optOutReason"
                    displayEmpty
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.optOut && formik.values.optOut.optOutReason
                        ? formik.values.optOut.optOutReason
                        : ""
                    }
                    disabled={!optedStatus}
                    onChange={(evt) => {
                      const newData = {
                        ...formik.values.optOut,
                        optOutReason: evt.target.value,
                      };
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        // we use the name to tell Formik which key of `values` to update
                        optOut: newData,
                      }));
                    }}
                    fullWidth
                    name="optOutReason"
                    notched
                  >
                    <MenuItem value={""}>{t("formLabel.none")}</MenuItem>
                    {OptOutReason.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t("LookupProspect." + item)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.errors &&
                      formik.touched.optOutReason &&
                      formik.errors.optOut?.optOutReason}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                container
                className={classes.customTopPadding}
                justify="space-between"
              >
                <Grid item xs={7}>
                  <TextField
                    name="optOutComment"
                    label={t("LookupProspect.optOutComment")}
                    variant="outlined"
                    multiline={true}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={!optedStatus}
                    fullWidth
                    size="small"
                    error={
                      formik.errors.optOut &&
                      formik.touched.optOutComment &&
                      Boolean(formik.errors.optOut.optOutComment)
                    }
                    helperText={
                      formik.errors &&
                      formik.touched.optOutComment &&
                      formik.errors.optOut?.optOutComment
                    }
                    value={
                      formik.values.optOut && formik.values.optOut.optOutComment
                        ? formik.values.optOut.optOutComment
                        : ""
                    }
                    onChange={(evt) => {
                      const newData = {
                        ...formik.values.optOut,
                        optOutComment: evt.target.value,
                      };
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        // we use the name to tell Formik which key of `values` to update
                        optOut: newData,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.datePicker}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      label={t("LookupProspect.optOutDate")}
                      format="MM/dd/yyyy"
                      onBlur={formik.handleBlur}
                      margin="dense"
                      disabled={!optedStatus}
                      value={
                        formik.values.optOut && formik.values.optOut.optOutDate
                      }
                      error={
                        formik.errors.optOut &&
                        Boolean(formik.errors.optOut.optOutDate)
                      }
                      helperText={
                        formik.errors && formik.errors.optOut?.optOutDate
                      }
                      onChange={(date, value) => {
                        const checkDate = Date.parse(date);
                        let newData = {};

                        if (!isNaN(checkDate)) {
                          newData = {
                            ...formik.values.optOut,
                            optOutDate: date.toISOString(),
                          };
                        } else {
                          newData = {
                            ...formik.values.optOut,
                            optOutDate: value,
                          };
                        }

                        formik.setValues((prevValues) => ({
                          ...prevValues,
                          // we use the name to tell Formik which key of `values` to update
                          optOut: newData,
                        }));
                      }}
                      id="optOutDate"
                      name="optOutDate"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.customTopPadding}
                justify="space-between"
              >
                <Grid item xs={7}>
                  <TextField
                    name="concern"
                    label={t("LookupProspect.concern")}
                    variant="outlined"
                    multiline={true}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    size="small"
                    value={
                      formik.values.concern &&
                      formik.values.concern.concernDescription
                    }
                    error={
                      formik.errors.concern &&
                      formik.touched.concernDescription &&
                      Boolean(formik.errors.concern.concernDescription)
                    }
                    helperText={
                      formik.errors &&
                      formik.touched.concernDescription &&
                      formik.errors.concern?.concernDescription
                    }
                    onChange={(evt) => {
                      let newData = null;
                      //Conditions to check we have to null optOut or not for yup validation.
                      if (evt.target.value) {
                        newData = {
                          ...formik.values.concern,
                          concernDescription: evt.target.value,
                          concernDate: format(new Date(), "yyyy-MM-dd"),
                        };
                      }
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        // we use the name to tell Formik which key of `values` to update
                        concern: newData,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.datePicker}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      label={t("LookupProspect.concernDate")}
                      format="MM/dd/yyyy"
                      onBlur={formik.handleBlur}
                      margin="dense"
                      value={
                        formik.values.concern &&
                        formik.values.concern.concernDate
                          ? formik.values.concern.concernDate
                          : null
                      }
                      disabled={!formik.values.concern}
                      error={
                        formik.errors.concern &&
                        Boolean(formik.errors.concern.concernDate)
                      }
                      helperText={
                        formik.errors && formik.errors.concern?.concernDate
                      }
                      onChange={(date, value) => {
                        const checkDate = Date.parse(date);
                        let newData = {};

                        if (!isNaN(checkDate)) {
                          newData = {
                            ...formik.values.concern,
                            concernDate: format(new Date(date), "yyyy-MM-dd"),
                          };
                        } else {
                          newData = {
                            ...formik.values.concern,
                            concernDate: value,
                          };
                        }

                        formik.setValues((prevValues) => ({
                          ...prevValues,
                          // we use the name to tell Formik which key of `values` to update
                          concern: newData,
                        }));
                      }}
                      id="concernDate"
                      name="concernDate"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.customTopPadding}>
                <TextField
                  name="notes"
                  label={t("LookupProspect.notes")}
                  variant="outlined"
                  multiline={true}
                  onBlur={formik.handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  size="small"
                  value={formik.values.note}
                  onChange={(evt) => {
                    formik.setFieldValue("note", evt.target.value);
                  }}
                />
              </Grid>
              <Grid
                container
                justify="flex-end"
                className={classes.customPadding}
              >
                <Grid container justify="flex-end" item xs={2}>
                  <Button
                    variant="outlined"
                    onClick={() => handleClickCancel()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container justify="flex-end" item xs={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      !formik.isValid ||
                      !formik.dirty ||
                      formik.isSubmitting ||
                      !Object.keys(diff1).length > 0
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Grid>
        <Grid item xs={12} className={classes.gridContainer}>
          <Typography variant="h6">
            {t(`ProspectDetailsCohortHistory.cohortHistoryLabel`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProspectDetailsCohortHistory mrn={prospectId} />
        </Grid>
        <Grid item xs={12} className={classes.gridContainer}>
          <Typography variant="h6">
            {t(`ProspectDetailsOutreachHistory.outreachHistoryLabel`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProspectDetailsOutreachHistory mrn={prospectId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditProspectPageContent;
