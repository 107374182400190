import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  downloadContainer: {
    color: theme.palette.primary.dark,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
