import axios from "axios";
import i18n from "./i18n";

// src/setupAxios.js
// Setup defaults and response interceptor for the axios HTTP library.
// Applications should not modify this file, and any changes to message strings in src/locals/en/common.json
//  should be made to the common.json file in the as-frontend-starter project.
export const setupAxios = () => {
  // If the base URL contains DEPLOY_ENV, update with the deployment environment from public/env.js
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL.replace(
    "DEPLOY_ENV",
    window.DEPLOY_ENV
  );
  axios.defaults.timeout = process.env.REACT_APP_API_TIMEOUT;

  // For any API error, set a default error message which the service can either accept or override
  axios.interceptors.response.use(
    (response) => {
      // Any status code of 2xx causes this function to trigger
      // Return the unmodified response back to the service for handling
      return response;
    },
    // Calculate an end-user appropriate message string
    (error) => {
      // Any results other than status code 2xx causes this function to trigger
      if (error.response) {
        // The request was made and the server responded with a status code other than 2xx
        // HTTP 4xx form validation errors
        if (
          error.response.data.detail &&
          Array.isArray(error.response.data.detail.properties) &&
          !!error.response.data.detail.properties[0]
        ) {
          error.message = generateMessageFromDetailProperties(
            error.response.data.detail.properties,
            error.response.data.status,
            error.response.data.code
          );
        }
        // All other errors (HTTP 5xx)
        else {
          // Address missing properties
          backfillMissingErrorProperties(error);

          // This maps the status & code values to the error messages stored in locals/en/common.json
          error.message = i18n.t(
            `common:apiDefaultErrorMessages.${error.response.data.status}.${error.response.data.code}`,
            error.response.data
          );
        }
        error.status = error.response.data.status; // HTTP status code
        error.code = error.response.data.code; // ENUM value of the error
      } else if (error.request) {
        // The request was made but no response was received
        // error.request is an instance of XMLHttpRequest in the browser
        error.message = i18n.t(
          "common:apiDefaultErrorMessages.REQUEST_TIMEOUT"
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        error.message = i18n.t("common:apiDefaultErrorMessages.REQUEST_ERROR");
      }

      // Return the default error message to the service in error.message
      // Also return error.status and error.code if the error came from the backend
      return Promise.reject(error);
    }
  );
};

const generateMessageFromDetailProperties = (properties, status, code) => {
  return properties
    .map((entry) => {
      return !!entry.message
        ? entry.message
        : i18n.t(`common:apiDefaultErrorMessages.${status}.${code}`, entry);
    })
    .join(", ");
};

const backfillMissingErrorProperties = (error) => {
  if (error.response.data.error === "Internal Server Error") {
    error.response.data.code = "SERVER_ERROR";
  } else if (error.response.data === "") {
    error.response.data = {
      status: error.response.status,
      code: error.response.statusText,
    };
  }
};
