import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

export const AutocompleteOption = ({ option, inputValue, classes }) => {
  const parts = getMatchedParts(option, inputValue);
  let className = "";
  if (classes) {
    className = option.isDummy
      ? classes.dummyAutocompleteOption
      : classes.autocompleteOption;
  }

  return (
    <div className={className}>
      <div>
        {parts.map((part, index) => {
          return (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const getMatchedParts = (option, inputValue) => {
  let matches;

  if (!!option.isDummy) {
    return [
      {
        highlight: false,
        text: option.displayText,
      },
    ];
  }

  if (option.value) {
    matches = match(option.displayText, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });
    return parse(option.displayText, matches);
  }
};
