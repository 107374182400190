import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -28,
  },
  gridContainer: {
    marginBottom: "15px",
  },
  titleContainer: {
    marginBottom: "15px",
    marginTop: "15px",
  },
  importSection: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: "12px",
  },
  formTextField: {
    width: "150px",
  },
  dragDropContainer: {
    textAlign: "center",
    backgroundColor: theme.palette.grey[300],
    border: `1px dashed ${theme.palette.grey[500]}`,
    width: "100%",
    margin: "30px 0px 10px 0px",
    paddingTop: "15px",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  importActionContainer: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "15px 0px",

    "& button": {
      marginRight: "30px",
    },
  },
  failedCellContainer: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      paddingLeft: "5px",
    },
  },
  tableScroll: {
    "& .MuiTableContainer-root": {
      overflowX: "auto",
    },
  },
}));
