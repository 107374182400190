import { makeStyles } from "@material-ui/core/styles";

const defaultDrawerWidth = 400;
export const useStyles = makeStyles(() => ({
  drawer: {
    width: "0px",
    flexShrink: 0,
    zIndex: 0,
  },
  drawerPaper: {
    width: `${defaultDrawerWidth}px`,
    border: "none",
    height: "100vh",
    position: "relative",
  },
}));
