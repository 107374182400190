import { Grid, Typography, Link } from "@material-ui/core";
import { useStyles } from "app/reports/TableauReportStyles";
import { useTranslation } from "react-i18next";
import PEPage from "app/shared/UI/PEPage";
import { handleTableauUrls } from "app/shared/utils";

const StudyReports = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const ReportContent = () => {
    return (
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <Link
              href={`${handleTableauUrls()}/StudySummaryDashboard`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="h5" noWrap>
                {t("TableauReport.studySummaryDashboardReport")}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.descContainer}>
              {t("TableauReport.studySummaryDashboardReportDesc")}
            </Typography>
          </Grid>

          <Grid item>
            <Link
              href={`${handleTableauUrls()}/ParticipantBreakdownDashboard`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="h5" noWrap>
                {t("TableauReport.participantBreakdownReport")}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.descContainer}>
              {t("TableauReport.participantBreakdownReportDesc")}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <PEPage
      hasDrawer={false}
      pageTitle={t("TableauReport.studyPageTitle")}
      hasBackButton={false}
      contentComponent={ReportContent}
      {...props}
    />
  );
};

export default StudyReports;
