import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "30px",
    paddingTop: "24px",
    paddingRight: "30px",
    paddingBottom: "24px",
  },
  space: { whiteSpace: "noWrap" },
  customGridMargin: {
    marginBottom: "15px",
  },
  datepicker: {
    marginTop: "0px",
  },
  tabHeader: {
    backgroundColor: theme.palette.grey[100],
  },
  customContactGridWidth: {
    width: "275px",
  },
  areaZipCodes: {
    whiteSpace: "nowrap",
  },
  contentNoWrap: {
    flexWrap: "nowrap",
  },
  scrollableContainer: {
    overflowX: "auto",
  },
}));

