import { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "app/shared/UI/PEErrorDialogStyles";
import {
  Dialog,
  DialogActions,
  Button,
  ListItemText,
  Divider,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Warning as WarningIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { renderContentBySplitingOnFullStop } from "../utils";

const PEErrorDialog = (props) => {
  const {
    onClose,
    open,
    errorsList,
    title,
    primarySubHeading,
    secondarySubHeading,
    primaryContent,
    secondaryContent,
    displayDynamicPrimarySubHeading,
  } = props;
  const [scroll] = useState("paper");
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} scroll={scroll}>
      <DialogTitle
        id="error-dialog-title"
        className={classes.errorSummaryTitle}
      >
        <WarningIcon className={classes.warning} />
        {title}
      </DialogTitle>
      <DialogContent className={classes.errorSummaryContent}>
        <List>
          {errorsList.map((content, index) => {
            return (
              <div key={index}>
                {index > 0 && <Divider component="li" />}
                <ListItem
                  alignItems="flex-start"
                  className={classes.contentListItem}
                >
                  <ListItemText
                    primary={
                      <span className={classes.itemTextBlock}>
                        {displayDynamicPrimarySubHeading ? (
                          <span className={classes.itemTextSpan}>
                            {content.type}
                          </span>
                        ) : (
                          primarySubHeading && (
                            <span className={classes.itemTextSpan}>
                              {primarySubHeading}
                            </span>
                          )
                        )}
                        <Typography
                          variant="body1"
                          className={classes.Typography}
                        >
                          {content[primaryContent]}
                        </Typography>
                      </span>
                    }
                    secondary={
                      <span component="span" className={classes.itemTextBlock}>
                        {secondarySubHeading && (
                          <span className={classes.itemTextSpan}>
                            {secondarySubHeading}
                          </span>
                        )}
                        <Typography
                          component="span"
                          className={classes.Typography}
                        >
                        {renderContentBySplitingOnFullStop(content[secondaryContent])}
                        </Typography>
                      </span>
                    }
                  />
                </ListItem>
              </div>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t(`Close`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PEErrorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PEErrorDialog;
