import styled from "styled-components/macro";

const PEChipContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default PEChipContainer;
